import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PollService } from '@services/cms/poll.service';
import { Poll } from '@models/poll';
import {Images} from "@constants/images";

@Component({
  selector: 'app-form-poll',
  templateUrl: './form-poll.component.html',
  styleUrls: ['./form-poll.component.scss']
})
export class FormPollComponent implements OnInit {
  public subtitle: string;
  public create: boolean;
  public showForm: boolean = false;
  public imageClose = Images.iconClosePopup;
  public disableInputLiveID = false;
  public error = false;
  public errors: any[] = [];

  constructor(
      public dialogRef: MatDialogRef<FormPollComponent>,
      public service: PollService,
      public images: Images,
      @Inject(MAT_DIALOG_DATA) public data: Poll
  ) {
    this.subtitle = this.service.modelClass.langBase + 'createEditSubtitle';
    this.service.modelClass.model = data;
    this.create = this.service.modelClass.model.pollID === 0;
    if (this.service.modelClass.model['liveID'] > 0) {
      this.disableInputLiveID = true;
    }
  }

  ngOnInit(): void {
    if (!this.create) {
      this.loadData();
    } else {
      this.showForm = true;
    }
  }

  private loadData = () => {
    this.showForm = false;
    this.service.getByID(this.service.modelClass.model['pollID']).then((response) => {
      if (response) {
        this.service.modelClass.model = this.service.data.data;
        this.showForm = true;
      }
    });
  }

  public sendForm = (): void => {
    const self = this;
    // console.log(this.create, this.service.modelClass.model);
    if (this.service.modelClass.model['escenaID'] === 0) {
      this.service.modelClass.model['escenaID'] = null;
    }
    if (this.create) {
      self.service.create().then((response) => {
        if (response) {
          // console.log(response);
          self.close(true);
        }
      });
    } else {
      self.service.update(self.service.modelClass.model[self.service.modelClass.idName]).then((response) => {
        if (response) {
          // console.log(response);
          self.close(true);
        }
      });
    }
  }

  public close = (reload: boolean = false) => {
    this.dialogRef.close({ data: { reload }});
  }
}
