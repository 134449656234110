import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractInputSelect } from '@components/field_form/select-inputs/abstract-input-select';
import { AbstractService } from '@services/Abstract.service';
import { HintService } from '@services/cms/hint.service';

@Component({
  selector: 'app-input-select-hint-type',
  templateUrl: './input-select-hint-type.component.html',
  styleUrls: ['./input-select-hint-type.component.scss']
})
export class InputSelectHintTypeComponent extends AbstractInputSelect implements OnInit {
  @Input() public serviceModel: AbstractService | null = null;
  @Input() public label = 'hintType';
  @Input() public name = '';
  @Input() public required = false;
  @Input() public multiple = false;
  @Input() public disabled = false;
  reloadData = true;
  list: any[] = [];
  selectTemporal: any[] = [];
  private isDataLoading = false;
  // Events
  @Output() changeAction = new EventEmitter<any>();
  @Output() loadAction = new EventEmitter<any>();

  constructor(
      public service: HintService
  ) {
    super(service);
  }

  ngOnInit(): void {
    console.log(this.list);
    this.loadData('data');
  }

  public loadData = (dataName: string | null) => {
    if (this.service !== null) {
      this.reloadData = true;
      this.service.getHintTypes().then(
          (data: any) => {
            if (data !== false) {
              this.reloadData = false;
              this.list = data.data;
            }
          }
      );
    }
  }

  public change = () => {
    this.changeAction.emit();
  }
}
