import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DataTablesModule } from 'angular-datatables';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
// import { NgxDropzoneModule } from 'ngx-dropzone';

/** Redux */
/** Copmponents */
import { ListComponent } from '@components/tables/list/list.component';
import { ButtonComponent } from '@components/button/button.component';
import { FieldFormComponent } from '@components/field_form/field_form.component';
import { FormBasicComponent } from '@components/form/basic/basic.component';
import { AccordionComponent } from '@components/accordion/accordion.component';
import { SelectLanguageComponent } from '@components/select_lang/selectLanguage.component';
import { ListPaginationBackComponent } from '@components/tables/list-pagination-back/list-pagination-back.component';
import { InputFileComponent } from './field_form/special-inputs/input-file/input-file.component';
import { InputImageComponent } from './field_form/special-inputs/input-image/input-image.component';
import { InputSelectLiveComponent } from './field_form/select-inputs/input-select-live/input-select-live.component';
import { InputSelectConnectDepartmentsComponent } from '@components/field_form/select-inputs/input-select-connect-departments/input-select-connect-departments.component';
import { InputSelectConnectCitiesComponent } from '@components/field_form/select-inputs/input-select-connect-cities/input-select-connect-cities.component';
import { InputSelectConnectTypeIdComponent } from '@components/field_form/select-inputs/input-select-connect-type-id/input-select-connect-type-id.component';
import { InputImageFormDataComponent } from '@components/field_form/special-inputs/input-image-formData/input-image-formData.component';
import { AlertComponent } from './alert/alert.component';
import { CardChatComponent } from './card/card-chat/card-chat.component';
import { InputSelectAccountComponent } from '@components/field_form/select-inputs/input-select-account/input-select-account.component';
import { InputSelectTopicComponent } from '@components/field_form/select-inputs/input-select-topic/input-select-topic.component';
import { CardStatsNumberComponent } from '@components/card/card-stats-number/card-stats-number.component';

import { ColorPickerComponent } from './color-picker/color-picker.component';
import { InputPickerColorComponent } from './field_form/special-inputs/input-picker-color/input-picker-color.component';
import { InputPickerColorLiveComponent } from './field_form/special-inputs/input-picker-color-live/input-picker-color-live.component';
import {ReviewQuestionFormComponent} from "@components/form/review-question-form/review-question-form.component";
import {InputSelectSceneComponent} from "@components/field_form/select-inputs/input-select-scene/input-select-scene.component";
import {InputSelectHintTypeComponent} from "@components/field_form/select-inputs/input-select-hint-type/input-select-hint-type.component";
import {InputSelectOfferComponent} from "@components/field_form/select-inputs/input-select-offer/input-select-offer.component";
import {CardChatSceneComponent} from "@components/card/card-chat-scene/card-chat-scene.component";
import {InputSelectThemeComponent} from "@components/field_form/select-inputs/input-select-theme/input-select-theme.component";




@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    DataTablesModule,
    MatTableModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    A11yModule,
    ClipboardModule,
    DragDropModule,
    PortalModule,
    ScrollingModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    // NgxDropzoneModule
  ],
    declarations: [
        ListComponent,
        ListPaginationBackComponent,
        ButtonComponent,
        FieldFormComponent,
        FormBasicComponent,
        AccordionComponent,
        SelectLanguageComponent,
        InputFileComponent,
        InputImageComponent,
        InputSelectLiveComponent,
        InputSelectConnectDepartmentsComponent,
        InputSelectConnectCitiesComponent,
        InputSelectConnectTypeIdComponent,
        InputImageFormDataComponent,
        AlertComponent,
        CardChatComponent,
        InputSelectAccountComponent,
        InputSelectTopicComponent,
        CardStatsNumberComponent,
        ColorPickerComponent,
        InputPickerColorComponent,
        InputPickerColorLiveComponent,
        ReviewQuestionFormComponent,
        InputSelectSceneComponent,
        InputSelectHintTypeComponent,
        InputSelectOfferComponent,
        CardChatSceneComponent,
        InputSelectThemeComponent
    ],
    exports: [
        ListComponent,
        ButtonComponent,
        FieldFormComponent,
        FormBasicComponent,
        AccordionComponent,
        A11yModule,
        ClipboardModule,
        DragDropModule,
        PortalModule,
        ScrollingModule,
        CdkStepperModule,
        CdkTableModule,
        CdkTreeModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSidenavModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        OverlayModule,
        SelectLanguageComponent,
        AlertComponent,
        CardChatComponent,
        CardStatsNumberComponent,
        ColorPickerComponent,
        ReviewQuestionFormComponent,
        CardChatSceneComponent
    ],
  providers: [
    /** Redux-Facade */
  ]
})
export class ComponentsModule { }
