import { createReducer, on } from '@ngrx/store';
import * as thisActions from '../actions/loader.actions';
import { Loadable } from '@models/loadable';

export const loaderFeatureKey = 'loader';

export interface LoaderPartialState {
  readonly [loaderFeatureKey]: Loadable;
}

export const initialState: Loadable = {
  success: true,
  error: '',
  okMessage: '',
  loading: false
};

export const loaderReducer = createReducer(initialState,
  on(thisActions.showLoader, state => {
    return {
      ...state,
      loading: true
    };
  }),
  on(thisActions.closeLoader, state => {
    return {
      ...state,
      loading: false
    };
  })
);
