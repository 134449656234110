import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractClass, TableCell } from '@classes/abstract.class';
import { MatTableDataSource } from '@angular/material/table';
import { Images } from '@constants/images';
import { NotificationFacade } from '@redux/notification.facade';
import { GetJsonValue } from '@pipes/getJsonValue';
import { TranslatePipe } from '@pipes/translate';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-table-list-pagination',
  templateUrl: './list-pagination-back.component.html',
  styleUrls: ['./list-pagination-back.component.scss'],
  providers: []
})

export class ListPaginationBackComponent implements OnInit {
  @Input() public myClass: AbstractClass | null = null;
  @Input() public data: any[] | null = null;
  @Input() public resultsPerPage = 15;
  @Input() public page = 0;
  @Input() public lastPage = 0;
  // ---
  @Input() activeActions = true;
  @Input() viewActive = false;
  // Events
  @Output() seacrhAction = new EventEmitter<any>();
  @Output() setResultsPerPageAction = new EventEmitter<any>();
  @Output() setPageAction = new EventEmitter<any>();
  @Output() createAction = new EventEmitter<any>();
  @Output() editAction = new EventEmitter<any>();
  @Output() downloadAction = new EventEmitter<any>();
  // --
  @Output() changeStatusAction = new EventEmitter<any>();

  public ids: any[] = [];
  public titles: TableCell[] = [];
  displayedColumns: string[] = [];
  public imageTrash = Images.iconTrash;
  public imageEdit = Images.iconEdit;
  public imageSearch = Images.iconSearch;
  public imageLast = Images.iconPaginatorLast;
  public imageFirst = Images.iconPaginatorFirst;
  public dataSource = new MatTableDataSource<any>();
  public pagesList: number [] = [];
  public popUpDownload = false;
  public downloadType = 'xlsx';
  public downloadTitles: TableCell[] = [];

  constructor(
      public images: Images,
      private notificationFacade: NotificationFacade,
      private changeDetectorRefs: ChangeDetectorRef,
      private getJsonValue: GetJsonValue,
      private translatePipe: TranslatePipe,
      private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    const self = this ;
    this.displayedColumns.push('Sel');
    self.myClass?.getTablesCells().map((t) => {
      this.displayedColumns.push(t.label);
    });
    if (!this.activeActions) {
      this.displayedColumns.push('Acciones');
    }
    // Paginator
    let start = [1, 2, 3, 4, 5];
    if (this.lastPage < 5) {
      start = [];
      for (let a = 1; a <= this.lastPage;  a++) {
        start.push(a);
      }
    }
    const end = [this.lastPage - 4, this.lastPage - 3, this.lastPage - 2, this.lastPage - 1, this.lastPage];
    const current = [this.page - 2, this.page - 1, this.page, this.page + 1, this.page + 2];
    this.pagesList = this.page < 3 ? start : this.page > (this.lastPage - 2) ? end : current;
    // SetData
    if (self.data !== null) {
      self.dataSource.data = self.data;
      this.changeDetectorRefs.detectChanges();
    }
  }

  public setResultsPerPage = (rpp: number) => this.setResultsPerPageAction.emit(rpp);
  public setPage = (id: number) => this.setPageAction.emit(id);
  public doFilter = ($event: any) => {
    $event.preventDefault();
    const valFilter = $('#filter').val();
    this.seacrhAction.emit(valFilter);
  }

  public updateSelect = (id: any) => {
    if (this.ids.includes(id)) {
      this.ids = this.ids.filter(i => i !== id);
    } else {
      this.ids.push(id);
    }
  }

  public idChecked = (id: any): boolean => {
    return this.ids.filter(t => t === id).length > 0;
  }

  public create = () => {
    this.ids = [];
    this.createAction.emit();
  }

  public edit = () => {
    const self = this;
    const id = self.myClass?.idName ?? '';
    const dataTemp = self.data?.filter( d => self.ids.includes(d[id]) ) ?? [];
    this.editAction.emit(dataTemp);
  }

  public changeStatus = (name: string, data: AbstractClass) => {
    if (this.myClass?.model) {
      const dataTemp: any = data;
      if (typeof this.myClass.model[name] === 'boolean') {
        dataTemp[name] = !dataTemp[name];
      } else if (typeof this.myClass.model[name] === 'number') {
        dataTemp[name] = dataTemp[name] !== 1 ? 1 : 0;
      }
      this.changeStatusAction.emit(dataTemp);
    }
  }

  public updateDownloadTitles = (title: TableCell) => {
    if (this.downloadTitles.includes(title)) {
      this.downloadTitles = this.downloadTitles.filter(t => t.name !== title.name);
    } else {
      this.downloadTitles.push(title);
    }
  }

  public download = () => {
    const data = {
      type: this.downloadType,
      titles: this.downloadTitles
    };
    this.downloadAction.emit(data);
  }
  public changeDownloadType = (type: string) => this.downloadType = type;
}
