import { Injectable } from '@angular/core';
import { CustomHttpService } from '@services/generals/customHttp.service';
import { AbstractService } from '@services/Abstract.service';
import { ChatMessageClass } from '@classes/chat-message.class';

@Injectable({ providedIn: 'root'})
export class ChatMessageService extends AbstractService {

    constructor(
        public customHttpService: CustomHttpService
    ) {
        super(customHttpService, new ChatMessageClass());
    }

    public getByLiveID = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/GetMessagesToAdminByLiveID/' + id).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }

    public getBySceneID = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/GetMessagesToAdminByEscenaID/' + id).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }

    public blockUser = (user: any , liveId:number) => {
        const self = this;
        const url = self.version + self.base;
        const request = {
            "email": user.userMSG.email,
            "name": user.userMSG.name,
            "liveID": liveId
          }
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(true, true, url + 'LiveUserChat/BlockUserChat', request).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }
}
