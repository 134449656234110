<div class="logo {{ menuLarge ? 'menu-large' : 'menu-short' }}">
  <a class="simple-text" [routerLink]="['/admin/home']" *ngIf="menuLarge">Cheil NVIVO</a>
  <div class="icon-menu" *ngIf="!menuLarge">
    <mat-icon class="material-icons">menu</mat-icon>
  </div>
  <a *ngIf="!isMobileMenu() && menuLarge && menuPermanent" (click)="changePermanent(false)"><i
      class="material-icons logo-icon-close">close</i></a>
  <a *ngIf="!isMobileMenu() && menuLarge && !menuPermanent" (click)="changePermanent(true)"><i
      class="material-icons logo-icon-close">menu</i></a>
  <a *ngIf="isMobileMenu() && menuLarge" (click)="closeMenu()"><i class="material-icons logo-icon-close">close</i></a>
</div>

<div class="sidebar-wrapper">
  <div class="user-info {{ menuLarge ? 'menu-large' : 'menu-short' }}" id="navigation">
    <ul class="navbar-nav">
      <li class="nav-item">
        <mat-menu #appMenu="matMenu">
          <ng-template matMenuContent>
            <button mat-menu-item (click)="logout()">{{ 'auth.logout' | translatePipe }}</button>
          </ng-template>
        </mat-menu>
        <a href="javascript:void(0)" mat-icon-button [matMenuTriggerFor]="appMenu" class="nav-link">
          <div class="user-name-div">
            <div class="col-12">
              <div class="image">
                <img src="{{ images.imageUserImage }}" alt="user">
              </div>
            </div>
            <div class="user-name">
              <p class="name">Cheil colombia<br>{{ userEmail }}</p>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
  <ul class="nav {{ menuLarge ? 'menu-large' : 'menu-short' }}">
    <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="nav-item">
      <a class="nav-link" [routerLink]="[menuItem.path]" *ngIf="menuItem.submenu.length == 0">
        <img src="{{ menuItem.icon }}" alt="{{ menuItem.title | translatePipe }}">
        <p (click)="openSubmenu(menuItem)">{{ menuItem.title | translatePipe }}</p>
      </a>
      <div class="nav-link" *ngIf="menuItem.submenu.length > 0">
        <img src="{{ menuItem.icon }}" alt="{{ menuItem.title | translatePipe }}">
        <p (click)="openSubmenu(menuItem)">{{ menuItem.title | translatePipe }}</p>
      </div>
      <ul *ngIf="menuItem.submenu.length > 0" class="sub-nav {{ menuItem.open ? 'open' : 'close' }}">
        <li routerLinkActive="active" *ngFor="let submenu of menuItem.submenu" class="sub-nav-item">
          <a class="sub-nav-link" [routerLink]="[submenu.path]">
            <p>{{ submenu.title | translatePipe }}</p>
          </a>
        </li>
      </ul>
    </li>
  </ul>

</div>
