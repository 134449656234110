import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-stats-number',
  templateUrl: './card-stats-number.component.html',
  styleUrls: ['./card-stats-number.component.scss']
})
export class CardStatsNumberComponent implements OnInit {
  @Input() public title = '';
  @Input() public value = '';
  @Input() public color = '#C3C3C3';

  constructor() {}

  ngOnInit(): void {
  }
}
