<mat-form-field class="mso-filter-input" *ngIf="serviceModel !== null && !reloadData">
    <mat-label>{{ serviceModel.modelClass.langBase + label | translatePipe }}</mat-label>
    <mat-select [multiple]="multiple" class="form-control" placeholder="{{ serviceModel.modelClass.langBase + label + '_placeholder' | translatePipe }}" [(ngModel)]="serviceModel.modelClass.model[name]" name="{{ name }}" (ngModelChange)="change()" [required]="required">
        <input class="select-search" (keyup)="onKey($event, 'city')">
        <div class="select-options-div">
            <mat-option [value]="c.id" *ngFor="let c of list">{{ c.city }}</mat-option>
        </div>
    </mat-select>
</mat-form-field>
<div *ngIf="reloadData"><p>{{ 'commons.filters.select.loader' | translatePipe }}</p></div>

