import {Component, Inject, Input, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Scene } from '@models/scene';
import { SceneService } from '@services/cms/scene.service';
import {Poll} from "@models/poll";
import {PollService} from "@services/cms/poll.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Images} from "@constants/images";
import {PollResponseClass} from "@classes/poll-response.class";
declare var configurations: any;
declare var $: any;

@Component({
  selector: 'app-show-poll',
  templateUrl: './show-poll.component.html',
  styleUrls: ['./show-poll.component.scss']
})
export class ShowPollComponent implements OnInit {
  public poll: Poll | null = null;
  public list: any[] | null = null;
  public title = 'admin.components.poll.title';
  public subtitle = 'admin.components.poll.subtitle';

  constructor(

      public dialogRef: MatDialogRef<ShowPollComponent>,
      public service: PollService,
      public myClass: PollResponseClass,
      public images: Images,
      @Inject(MAT_DIALOG_DATA) public data: Poll
  ) {
    this.poll = data;
    this.subtitle = this.service.modelClass.langBase + 'createEditSubtitle';
    this.service.modelClass.model = data;
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {
    this.list = null;
    if (this.poll !== null) {
      this.service.getByPollID(this.poll.pollID).then((response: any) => {
        if (response) {
          // console.log(this.service.data)
          this.list = this.service.data.data;
          // console.log(this.list);
        }
      });
    }
  }

  public close = (reload: boolean = false) => {
    this.dialogRef.close({ data: { reload }});
  }
}
