<div *ngIf="service !== null" class="my-field-form">
  <mat-slide-toggle
    *ngIf="cellType === 'active'"
    class="my-slide-toggle"
    [checked]="service.modelClass.model[name] || service.modelClass.model[name] === 1"
    [required]="required"
    [disabled]="disable"
    (change)="select(name, service.modelClass.model[name])">
    <mat-label>{{ service.modelClass.langBase + label | translatePipe }}</mat-label>
  </mat-slide-toggle>

  <div *ngIf="cellType === 'image'">
    <app-input-image
            [service]="service"
            [name]="name"
            [label]="label"
            [required]="required"
            [multiple]="multiple"
    ></app-input-image>
  </div>

  <div *ngIf="cellType === 'image-form-data'">
    <app-input-image-form-data
            [service]="service"
            [name]="name"
            [label]="label"
            [required]="required"
            [multiple]="multiple"
    ></app-input-image-form-data>
  </div>

  <div *ngIf="cellType === 'liveID'">
    <app-input-select-live [serviceModel]="service" [name]="name" [disabled]="disable" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-live>
  </div>

  <div *ngIf="cellType === 'themeID'">
    <app-input-select-theme [serviceModel]="service" [name]="name" [disabled]="disable" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-theme>
  </div>

  <div *ngIf="cellType === 'cheilConnectDepartments'">
    <app-input-select-connect-departments [serviceModel]="service" [name]="name" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-connect-departments>
  </div>

  <div *ngIf="cellType === 'cheilConnectCities'">
    <app-input-select-connect-cities [serviceModel]="service" [name]="name" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-connect-cities>
  </div>

  <div *ngIf="cellType === 'cheilConnectTypeId'">
    <app-input-select-connect-type-id [serviceModel]="service" [name]="name" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-connect-type-id>
  </div>

  <div *ngIf="cellType === 'accountID'">
    <app-input-select-account [serviceModel]="service" [name]="name" [disabled]="disable" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-account>
  </div>

  <div *ngIf="cellType === 'topicID'">
    <app-input-select-topic [serviceModel]="service" [name]="name" [disabled]="disable" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-topic>
  </div>

  <div *ngIf="cellType === 'sceneID'">
    <app-input-select-scene [serviceModel]="service" [name]="name" [disabled]="disable" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-scene>
  </div>

  <div *ngIf="cellType === 'offerID'">
    <app-input-select-offer [serviceModel]="service" [name]="name" [disabled]="disable" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-offer>
  </div>

  <div *ngIf="cellType === 'hintType'">
    <app-input-select-hint-type [serviceModel]="service" [name]="name" [disabled]="disable" [label]="label" [required]="required" [multiple]="multiple"></app-input-select-hint-type>
  </div>

  <div *ngIf="cellType === 'color'">
   <app-input-picker-color [serviceModel]="service" [name]="name" [disabled]="disable" [label]="label" [required]="required" [multiple]="multiple"></app-input-picker-color>
  </div>
  <div *ngIf="cellType === 'color-live'">
  <app-input-picker-color-live [serviceModel]="service" [name]="name" [disabled]="disable" [label]="label" [required]="required" [multiple]="multiple"></app-input-picker-color-live>
  </div>
  <mat-form-field [ngSwitch]="cellType" *ngIf="!exceptions.includes(cellType)">
    <mat-label>{{ service.modelClass.langBase + label | translatePipe }}</mat-label>
    <textarea style="width:100%;" matInput placeholder="{{ service.modelClass.langBase + label | translatePipe }}" *ngSwitchCase="'textarea'" [(ngModel)]="service.modelClass.model[name]" name="{{ name }}" [required]="required" [disabled]="disable"></textarea>

    <mat-select placeholder="{{ 'commons.forms.select' | translatePipe }}" [(ngModel)]="service.modelClass.model[name]" *ngSwitchCase="'boolean'" name="{{ name }}" [required]="required" [disabled]="disable">
      <mat-option [value]="true">Verdadero</mat-option>
      <mat-option [value]="false">Falso</mat-option>
    </mat-select>

    <mat-select placeholder="{{ 'commons.forms.select' | translatePipe }}" [(ngModel)]="service.modelClass.model[name]" *ngSwitchCase="'color-text'" name="{{ name }}" [required]="required" [disabled]="disable">
      <mat-option [value]="'#FFFFFF'">Blanco</mat-option>
      <mat-option [value]="'#000000'">Negro</mat-option>
    </mat-select>

    <!-- ngx-mat-file-input placeholder="{{ label }}" *ngSwitchCase="'image'" (change)="selectFile($event, name)"></ngx-mat-file-input -->

    <input matInput

           [placeholder]="service.modelClass.langBase + label | translatePipe"
           [(ngModel)]="service.modelClass.model[name]"
           *ngSwitchDefault type="{{ cellType }}" name="{{ name }}" [disabled]="disable" [required]="required">

  </mat-form-field>




</div>
