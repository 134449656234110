import { Component, OnInit ,  } from '@angular/core';


@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent implements OnInit {

  public color: string = '#2889e9';


  constructor(

  ) { }

  ngOnInit(): void {
  }



}
