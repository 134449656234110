import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TopicService } from '@services/cms/topic.service';
import { Topic } from '@models/topic';

@Component({
  selector: 'app-form-topic',
  templateUrl: './form-topic.component.html',
  styleUrls: ['./form-topic.component.scss']
})
export class FormTopicComponent implements OnInit {
  public subtitle: string;
  private create: boolean;

  constructor(
      public dialogRef: MatDialogRef<FormTopicComponent>,
      public service: TopicService,
      @Inject(MAT_DIALOG_DATA) public data: Topic
  ) {
    this.subtitle = this.service.modelClass.langBase + 'createEditSubtitle';
    this.service.modelClass.model = data;
    this.create = this.service.modelClass.model[this.service.modelClass.idName] === 0;
    if (this.service.modelClass.model['accountID'] > 0) {
      this.service.modelClass.formCells.filter(x => x.name === 'accountID')[0].disable = true;
    }
  }

  ngOnInit(): void {
  }

  sendForm(): void {
    const self = this;
    if (this.create) {
      self.service.create().then((response) => {
        if (response) {
          self.close(true);
        }
      });
    } else {
      self.service.update(self.service.modelClass.model[self.service.modelClass.idName]).then((response) => {
        if (response) {
          self.close(true);
        }
      });
    }
  }

  public close = (reload: boolean = false) => {
    this.dialogRef.close({ data: { reload }});
  }
}
