import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() title = '';
  @Input() type = '';
  @Input() description = '';
  @Input() success = '';
  @Input() primaryButton = 'ok';
  @Input() secondaryButton = 'cancel';
  @Output() primaryAction = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public sendAction = (r: boolean) => {
    this.primaryAction.emit(r);
  }
}
