import { Injectable } from '@angular/core';
import { AbstractClass } from './abstract.class';
import { Account } from '@models/account';

@Injectable({
  providedIn: 'root'
})
export class AccountClass extends AbstractClass{
  model: Account;
  modelName = 'Account';
  langBase = 'admin.components.account.';
  idName = 'accountID';
  formCells = [
    // tslint:disable-next-line:max-line-length
    { name: 'name', label: 'name', cellType: 'text', disable: false, required: true, cols: '6', order: 1, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'description', label: 'description', cellType: 'text', disable: false, required: true, cols: '6', order: 2, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'email', label: 'email', cellType: 'email', disable: false, required: true, cols: '6', order: 3, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'isActive', label: 'isActive', cellType: 'active', disable: false, required: true, cols: '6', order: 4, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'logo', label: 'logo', cellType: 'image', disable: false, required: false, cols: '6', order: 5, multiple: false }
  ];
  formTabs = [];
  formAccordion = [];
  tableCells = [
    { name: 'logo', label: 'logo', cellType: 'image', order: false, download: true },
    { name: 'name', label: 'name', cellType: 'text', order: false, download: true },
    { name: 'email', label: 'email', cellType: 'text', order: false, download: true },
    { name: 'isActive', label: 'isActive', cellType: 'active-text', order: false, download: true }
  ];
  route = 'admin/account';
  stringsToRemove = [''];

  constructor()
  {
    super();
    this.model = {
      accountID: 0,
      name: '',
      createdBy:'',
      createdAt:'',
      description: '',
      email: '',
      logo: null,
      isActive: true
    };
  }
}
