import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Images } from '@constants/images';
import { Subscription } from 'rxjs';
import { PasswordRecoveryComponent } from '@components/passwordRecovery/passwordRecovery.component';
import { NotificationFacade } from '@redux/notification.facade';
import { AuthService } from "@services/auth/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  public title = 'title';
  public userName = '';
  public password = '';
  public typeInput = 'password';
  public showPassword = false;
  // @ts-ignore
  public unsubscribeAuth: Subscription;

  constructor(
    public notificationFacade: NotificationFacade,
    public router: Router,
    public images: Images,
    public dialog: MatDialog,
    public service: AuthService
  ) {
    const self = this;
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {
    if (this.service.isAuthenticated()) {
      this.router.navigateByUrl('/admin/home');
    }
    else {
      this.service.logout();
    }
  }

  public login = () => {
    this.service.login(this.userName, this.password).then(
        (data: any) => {
          if (data !== false) {
            this.notificationFacade.setSuccess('Se ha iniciado sesion correctamente');
            this.router.navigateByUrl('admin/home');
          }
        }
    );
  }

  public openDialog = (): void => {
    const dialogRef = this.dialog.open(PasswordRecoveryComponent, {
      width: '500px',
      data: { email: this.userName }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(['The dialog was closed', result]);
    });
  }
}
