<mat-form-field class="mso-filter-input" *ngIf="serviceModel !== null && !reloadData">
    <mat-label>{{ serviceModel.modelClass.langBase + label | translatePipe }}</mat-label>
    <mat-select [disabled]="disabled" [multiple]="multiple" class="form-control" placeholder="{{ 'commons.forms.select' | translatePipe }}" [(ngModel)]="serviceModel.modelClass.model[name]" name="{{ name }}" (ngModelChange)="change()" [required]="required">
        <input class="select-search" (keyup)="onKey($event, 'nombreEscena')">
        <div class="select-options-div">
            <mat-option [value]="" [disabled]="true">{{ 'commons.forms.select' | translatePipe }}</mat-option>
            <mat-option [value]="l.escenaID" *ngFor="let l of list">{{ l.nombreEscena }}</mat-option>
        </div>
    </mat-select>
</mat-form-field>
<div *ngIf="reloadData"><p>{{ 'commons.filters.select.loader' | translatePipe }}</p></div>

