<div class="card table">
  <div class="card-body">
    <div class="table-responsive" *ngIf="myClass !== null && data !== null">
      <!-- mat-form-field class="search-input" appearance="standard">
        <img src="{{ imageSearch }}" alt="icon_search">
        <input matInput id="filter" type="text" (keyup)="doFilter($event)" placeholder="{{ 'commons.filter' | translatePipe }}">
      </mat-form-field -->
      <button class="btn-icon" type="button" (click)="create()"><img src="{{ images.iconPlusCircle }}" alt="icon_plus_circle"></button>
      <button class="btn-icon" type="button" (click)="edit()"><img src="{{ imageEdit }}" alt="icon_edit"></button>
      <!-- button class="btn-icon"><img src="{{ imageTrash }}" alt="icon_trash"></button -->
      <div class="download-div">
        <button class="btn-text" type="button" (click)="popUpDownload = true">{{ 'commons.lists.download' | translatePipe }}</button>
        <div class="download-form" *ngIf="popUpDownload">
          <div class="background" (click)="popUpDownload = false"></div>
          <div class="card">
            <div class="card-body">
              <div class="download-type row">
                <h3 class="col-12">{{ 'commons.lists.download' | translatePipe }}</h3>
                <p class="col-6 col-md-4"><input type="radio" name="downloadType" [ngModel]="downloadType" (click)="changeDownloadType('xlsx')" [value]="'xlsx'" [checked]="downloadType === 'xlsx'"> Excel</p>
                <!-- p class="col-6 col-md-4"><input type="radio" name="downloadType" [ngModel]="downloadType" (click)="changeDownloadType('csv')" [value]="'csv'" [checked]="downloadType === 'cvs'"> Csv</p -->
              </div>
              <div class="div-checkbox row">
                <h3 class="col-12">{{ 'commons.lists.downloadColumns' | translatePipe }}</h3>
                <p class="col-6 col-sm-4" *ngFor="let title of myClass.getDownloadTitles()"><input type="checkbox" name="{{ title.name }}" [value]="title" placeholder="{{ title.name }}" (change)="updateDownloadTitles(title)">{{ myClass.langBase + title.label | translatePipe }}</p>
                <span class="checkmark"></span>
              </div>
            </div>
            <div class="card-footer">
              <button class="btn-text secondary" (click)="download()">{{ 'commons.accept' | translatePipe }}</button>
              <button class="btn-text" (click)="popUpDownload = false">{{ 'commons.cancel' | translatePipe }}</button>
            </div>
          </div>
        </div>
      </div>
      <table mat-table [dataSource]="dataSource" matSort class="table">
        <ng-container matColumnDef="Sel">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let data">
            <div class="div-checkbox">
              <input type="checkbox" name="{{ data[myClass.idName] }}" [value]="data[myClass.idName]" [checked]="idChecked(data[myClass.idName])" (change)="updateSelect(data[myClass.idName])">
              <span class="checkmark"></span>
            </div>
          </td>
        </ng-container>
        <ng-container *ngFor="let title of myClass.getTablesCells()" matColumnDef="{{ title.label }}">
          <th mat-header-cell *matHeaderCellDef>{{ myClass.langBase + title.label | translatePipe }}</th>
          <td mat-cell *matCellDef="let data" [ngSwitch]="title.cellType">
            <img src="{{ data[title.name] }}" class="img-fluid img-max-h-100" *ngSwitchCase="'image'" />
            <p *ngSwitchCase="'date'">{{ (data | getJsonValue:title.name) | date: 'y/MM/dd HH:mm:ss' }}</p>
            <a *ngSwitchCase="'url'" href="{{ data | getJsonValue:title.name }}" target="_blank">{{ 'Link' }}</a>
            <div *ngSwitchCase="'active'" class="div-status">
              <mat-slide-toggle
                [checked]="data[title.name] || data[title.name] === 1"
                (change)="changeStatus(title.name, data)">
              </mat-slide-toggle>
            </div>
            <p *ngSwitchCase="'storeType'">{{ (data | getJsonValue:title.name) === '0' ? 'Física' : (data | getJsonValue:title.name) === '1' ? 'Virtual' : '-' }}</p>
            <p *ngSwitchDefault>{{ data | getJsonValue:title.name }}</p>
          </td>
        </ng-container>
        <ng-container matColumnDef="Acciones" *ngIf="!activeActions">
          <th mat-header-cell *matHeaderCellDef>Acciones</th>
          <td mat-cell *matCellDef="let data">
            <a [routerLink]="['./../edit/'+ data['id']]">
              <span class="material-icons">edit</span>
            </a>
            <!-- a style="cursor: pointer; color: red;" (click)="deleteMethod(data['id'])">
              <span class="material-icons">delete</span>
            </a -->
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="my-paginator">
        <div class="my-paginator-pages">
          <div (click)="setPage(page === 0 ? 1 : page)" class="my-paginator-pages-first"><img src="{{ imageFirst }}" alt="first"></div>
          <div *ngFor="let p of pagesList" (click)="setPage(p)" class="middle {{ page === p ? 'active' : '' }}">{{ p }}</div>
          <div (click)="setPage(page === lastPage ? lastPage : page + 2)" class="my-paginator-pages-last"><img src="{{ imageLast }}" alt="last"></div>
        </div>
        <div class="my-paginator-size-options">
          <p class="my-paginator-size-options-title">{{ 'commons.lists.results' | translatePipe }}</p>
          <div class="my-paginator-size-options-div">
            <div (click)="setResultsPerPage(15)" class="{{ resultsPerPage === 15 ? 'active' : '' }}"><p>{{ '15' }}</p></div>
            <div (click)="setResultsPerPage(30)" class="middle-left {{ resultsPerPage === 30 ? 'active' : '' }}"><p>{{ '30' }}</p></div>
            <div (click)="setResultsPerPage(50)" class="middle-right {{ resultsPerPage === 50 ? 'active' : '' }}"><p>{{ '50' }}</p></div>
            <div (click)="setResultsPerPage(100)" class="{{ resultsPerPage === 100 ? 'active' : '' }}"><p>{{ '100' }}</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
