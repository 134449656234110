<div class="admin-content-header">
    <h1 class="admin-content-title">{{ title | translatePipe }}</h1>
</div>
<hr>
<app-table-list
        *ngIf="list !== null"
        [myClass]="service.modelClass"
        [data]="list"
        (createAction)="newForm()"
        [activeActions]="false"
        [showEditAction]="true"
        (editAction)="editForm($event)"
        [showViewAction]="true"
        (viewAction)="openShowComponent($event)"
        [showPlayAndStopAction]="true"
        (playAndStopAction)="setAlert($event)"
        [varLiveOnAir]="'liveOnAir'"
        [varPlayStop]="'livePlay'"
></app-table-list>
<app-alert
        *ngIf="showAlert"
        [title]="titleAlert"
        [type]="'action'"
        [description]="descriptionAlert"
        [success]="'warning'"
        [primaryButton]="'ok'"
        [secondaryButton]="'cancel'"
        (primaryAction)="playStopLive($event)"
></app-alert>
