import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractInputSelect } from '@components/field_form/select-inputs/abstract-input-select';
import { AbstractService } from '@services/Abstract.service';
import { OfferService } from "@services/cms/offer.service";

@Component({
  selector: 'app-input-select-offer',
  templateUrl: './input-select-offer.component.html',
  styleUrls: ['./input-select-offer.component.scss']
})
export class InputSelectOfferComponent extends AbstractInputSelect implements OnInit {
  @Input() public serviceModel: AbstractService | null = null;
  @Input() public label = 'store';
  @Input() public name = '';
  @Input() public required = false;
  @Input() public multiple = false;
  @Input() public disabled = false;
  reloadData = true;
  list: any[] = [];
  selectTemporal: any[] = [];
  private isDataLoading = false;
  // Events
  @Output() changeAction = new EventEmitter<any>();
  @Output() loadAction = new EventEmitter<any>();

  constructor(
      public service: OfferService
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.loadData('data');
  }

  public change = () => {
    this.changeAction.emit();
  }

  public loadData = (dataName: string | null) => {
    if (this.service !== null && this.serviceModel !== null) {
      console.log(this.serviceModel.modelClass.model);
      this.reloadData = true;
      if (this.serviceModel.modelClass.model['liveID'] !== null && this.serviceModel.modelClass.model['liveID'] !== undefined) {
        this.service.getByLiveID(this.serviceModel.modelClass.model['liveID']).then(
            (data) => {
              if (data === true) {
                this.reloadData = false;
                // console.log(this.service?.data);
                if (dataName !== null) {
                  this.list = this.service?.data[dataName];
                } else {
                  this.list = this.service?.data;
                }
              }
            }
        );
      } else {
        this.service.getAll().then(
            (data) => {
              if (data === true) {
                this.reloadData = false;
                // console.log(this.service?.data);
                if (dataName !== null) {
                  this.list = this.service?.data[dataName];
                } else {
                  this.list = this.service?.data;
                }
              }
            }
        );
      }
    }
  }
}
