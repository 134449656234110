import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractInputSelect } from '@components/field_form/select-inputs/abstract-input-select';
import { AbstractService } from '@services/Abstract.service';
import { TopicService } from '@services/cms/topic.service';

@Component({
  selector: 'app-input-select-topic',
  templateUrl: './input-select-topic.component.html',
  styleUrls: ['./input-select-topic.component.scss']
})
export class InputSelectTopicComponent extends AbstractInputSelect implements OnInit {
  @Input() public serviceModel: AbstractService | null = null;
  @Input() public label = 'account';
  @Input() public name = '';
  @Input() public required = false;
  @Input() public multiple = false;
  @Input() public disabled = false;
  reloadData = true;
  list: any[] = [];
  selectTemporal: any[] = [];
  private isDataLoading = false;
  // Events
  @Output() changeAction = new EventEmitter<any>();
  @Output() loadAction = new EventEmitter<any>();

  constructor(
      public service: TopicService
  ) {
    super(service);
  }

  ngOnInit(): void {
    this.loadData('data');
  }

  public change = () => {
    this.changeAction.emit();
  }
}
