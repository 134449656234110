import { Injectable } from '@angular/core';
import { AbstractClass } from './abstract.class';
import { Offer } from '@models/offer';
import { Live } from '@models/live';

@Injectable({
  providedIn: 'root'
})
export class OfferClass extends AbstractClass {
  model: Offer;
  modelName = 'Offerts';
  langBase = 'admin.components.offer.';
  idName = 'offertID';
  formCells = [
    // tslint:disable-next-line:max-line-length
    { name: 'liveID', label: 'live', cellType: 'liveID', disable: false, required: true, cols: '6', order: 1, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'productName', label: 'productName', cellType: 'text', disable: false, required: true, cols: '6', order: 2, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'productDescription', label: 'productDescription', cellType: 'textarea', disable: false, required: true, cols: '12', order: 3, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'productURL', label: 'productURL', cellType: 'text', disable: false, required: true, cols: '12', order: 4, multiple: false },
      // tslint:disable-next-line:max-line-length
      { name: 'order', label: 'order', cellType: 'number', disable: false, required: false, cols: '12', order: 5, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'regularPrice', label: 'regularPrice', cellType: 'number', disable: false, required: true, cols: '6', order: 6, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'showRegularPrice', label: 'showRegularPrice', cellType: 'active', disable: false, required: false, cols: '6', order: 7, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'offertPrice', label: 'offertPrice', cellType: 'number', disable: false, required: true, cols: '6', order: 8, multiple: false },

    // tslint:disable-next-line:max-line-length
    { name: 'showOffertPrice', label: 'showOffertPrice', cellType: 'active', disable: false, required: false, cols: '6', order: 9, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'isTemporalOffert', label: 'isTemporalOffert', cellType: 'active', disable: false, required: false, cols: '12', order: 10, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'startDateTime', label: 'startDateTime', cellType: 'datetime-local', disable: false, required: false, cols: '6', order: 11, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'endDateTime', label: 'endDateTime', cellType: 'datetime-local', disable: false, required: false, cols: '6', order: 12, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'photo', label: 'photo', cellType: 'image', disable: false, required: false, cols: '6', order: 13, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'isActive', label: 'isActive', cellType: 'active', disable: false, required: false, cols: '6', order: 14, multiple: false }
  ];
  formTabs = [];
  formAccordion = [];
  tableCells = [
    { name: 'live.liveName', label: 'live', cellType: 'text', order: false, download: true },
    { name: 'productName', label: 'productName', cellType: 'text', order: false, download: true },
    { name: 'productDescription', label: 'productDescription', cellType: 'text', order: false, download: true },
    { name: 'productURL', label: 'productURL', cellType: 'url', order: false, download: true },
    { name: 'regularPrice', label: 'regularPrice', cellType: 'currency', order: false, download: true },
    { name: 'offertPrice', label: 'offertPrice', cellType: 'currency', order: false, download: true },
    { name: 'offertClics', label: 'offertClics', cellType: 'number', order: false, download: true },
    { name: 'productImage', label: 'photo', cellType: 'image', order: false, download: true },
    { name: 'isActive', label: 'isActive', cellType: 'active-text', order: false, download: true }
  ];
  route = 'admin/live';
  stringsToRemove = [];

  constructor() {
    super();
    this.model = {
      offertID: 0,
      liveID: 0,
      productName: '',
      productDescription: '',
      productURL: '',
      regularPrice: 0,
      showRegularPrice: true,
      offertPrice: 0,
      showOffertPrice: true,
      photo: null,
      isActive: false,
      order: 0,
      isTemporalOffert: false,
      startDateTime: new Date(),
      endDateTime: new Date()
    };
  }
}
