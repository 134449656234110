import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Live } from '@models/live';
import { LiveService } from '@services/cms/live.service';

@Component({
  selector: 'app-form-live',
  templateUrl: './form-live.component.html',
  styleUrls: ['./form-live.component.scss']
})
export class FormLiveComponent implements OnInit {
  public subtitle = 'admin.components.live.createEditSubtitle';
  private create: boolean;

  constructor(
      public dialogRef: MatDialogRef<FormLiveComponent>,
      public service: LiveService,
      @Inject(MAT_DIALOG_DATA) public data: Live
  ) {
    this.service.modelClass.model = data;
    this.create = this.service.modelClass.model.liveID === 0;
    if (this.service.modelClass.model['topicID'] > 0) {
      this.service.modelClass.formCells.filter(x => x.name === 'accountID')[0].disable = true;
      this.service.modelClass.formCells.filter(x => x.name === 'topicID')[0].disable = true;
    }
  }

  ngOnInit(): void {
  }

  sendForm(): void {
    const self = this;
    if (this.create) {
      self.service.create().then((response) => {
        if (response) {
          self.close(true);
        }else{
          // console.log(response);
        }
      });
    } else {
      self.service.update(self.service.modelClass.model[self.service.modelClass.idName]).then((response) => {
        if (response) {
          self.close(true);
        }
      });
    }
  }

  public close = (reload: boolean = false) => {
    this.dialogRef.close({ data: { reload }});
  }
}
