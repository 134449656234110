import {Component, Inject, OnDestroy} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { NotificationFacade } from '@redux/notification.facade';

@Component({
  selector: 'app-password-recoverry',
  templateUrl: './passwordRecovery.component.html',
  styleUrls: ['./passwordRecovery.component.scss']
})
export class PasswordRecoveryComponent implements OnDestroy {
  public title = 'title';
  public email = '';
  public password = '';
  public code = '';
  public passwordRepeat = '';
  public showPassword = false;
  public showPasswordRepeat = false;
  public codeSent = false;
  public passwordEquals = false;
  public correctPassword = false;
  public showHelpPassword = false;
  // @ts-ignore
  public unsuscribePasswordRecovery: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PasswordRecoveryComponent>,
    public notificationFacade: NotificationFacade,
    @Inject(MAT_DIALOG_DATA) public data: { email: string }
  ) {
    const self = this;
  }

  // tslint:disable-next-line:typedef
  public ngOnDestroy() { }

  public sendCode = () => {
    console.log([this.email, this.password, this.code, this.passwordRepeat]);
  }

  public validatePassword = () => {
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    const special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    this.passwordEquals = this.password === this.passwordRepeat;
    this.correctPassword = !!(this.password.match(lowerCaseLetters) && this.password.match(upperCaseLetters) &&
      this.password.match(special) && this.password.match(numbers) && this.password.length >= 6);
  }

  public changePassword = () => {
    if (!this.passwordEquals) {
      this.notificationFacade.setNotification('Las contraseñas no coinciden.');
    } else if (!this.correctPassword) {
      this.notificationFacade.setNotification('La contraseña no es segura. Debe tener al menos una mayúscula, un caracter numérico, un caracter especial y una longitud mínima de 6 caracteres.');
    } else { }
  }

  public cancel = () => {
    this.unsuscribePasswordRecovery.unsubscribe();
    this.dialogRef.close();
  }

  public goBack = () => {
    this.codeSent = false;
  }
}
