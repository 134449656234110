import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HintService } from '@services/cms/hint.service';
import { Hint } from '@models/hint';
import {Images} from "@constants/images";
import {FormCell} from "@classes/abstract.class";

@Component({
  selector: 'app-form-hint',
  templateUrl: './form-hint.component.html',
  styleUrls: ['./form-hint.component.scss']
})
export class FormHintComponent implements OnInit {
  public title: string;
  public imageClose = Images.iconClosePopup;
  private create: boolean;
  public error = false;
  public errors: any[] = [];

  constructor(
      public dialogRef: MatDialogRef<FormHintComponent>,
      public service: HintService,
      @Inject(MAT_DIALOG_DATA) public data: Hint
  ) {
    this.title = this.service.modelClass.langBase + 'createEditSubtitle';
    this.service.modelClass.model = data;
    this.create = this.service.modelClass.model[this.service.modelClass.idName] === 0;
    if (this.service.modelClass.model['liveID'] > 0) {
      this.service.modelClass.formCells.filter(x => x.name === 'liveID')[0].disable = true;
    }
  }

  ngOnInit(): void {
  }

  sendForm(): void {
    const self = this;
    self.error = false;
    self.errors = [];
    self.service?.modelClass.getInputsForm().filter((i: FormCell) => i.required).map((value: FormCell, id: number) => {
      // console.log(self.service?.modelClass.model[value.name]);
      if (self.service?.modelClass.model[value.name] === undefined || self.service?.modelClass.model[value.name] === null
          || self.service?.modelClass.model[value.name] === '' || self.service?.modelClass.model[value.name].length === 0) {
        self.error = true;
        self.errors.push(value.label);
      }
    });
    if (!self.error) {
      if (self.create) {
        self.service.create().then((response) => {
          if (response) {
            self.close(true);
          }
        });
      } else {
        self.service.update(self.service.modelClass.model[self.service.modelClass.idName]).then((response) => {
          if (response) {
            self.close(true);
          }
        });
      }
    }
  }

  public close = (reload: boolean = false) => {
    this.dialogRef.close({ data: { reload }});
  }
}
