import { Injectable } from '@angular/core';
import { CustomHttpService } from '@services/generals/customHttp.service';

@Injectable({
  providedIn: 'root'
})
export class LiveStatsService {

  constructor(
    public customHttpService: CustomHttpService,
  ) { }

  public getUsersByLiveTime = (liveBroadcastID: number, time: number) => {
    const self = this;
    const request = {
      liveID: liveBroadcastID,
      Time: time
    };
    return new Promise(
      resolve => {
        self.customHttpService.httpGET(true, true, 'v1/api/LiveStats/GetUsersByLiveTime', request).then(
          (response: any) => {
            if (response === false) { resolve(response); }
            else {
              resolve(response);
            }
          }
        );
      }
    );
  }

  public getMessagesByLiveTime = (liveBroadcastID: number, time: number) => {
    const self = this;
    const request = {
      liveID: liveBroadcastID,
      Time: time
    };
    return new Promise(
      resolve => {
        self.customHttpService.httpGET(true, true, 'v1/api/LiveStats/GetMessagesByLiveTime', request).then(
          (response: any) => {
            if (response === false) { resolve(response); }
            else {
              resolve(response);
            }
          }
        );
      }
    );
  }

  public getLiveByID = (id: number) => {
    const self = this;
    return new Promise(
      resolve => {
        self.customHttpService.httpGET(true, true, 'v1/api/Lives/' + id).then(
          (response) => {
            resolve(response);
          }
        );
      }
    );
  }

  public getGeneralByLive = (liveBroadcastID: number) => {
    const self = this;
    const request = {
      liveID: liveBroadcastID,
      Time: 1
    };
    return new Promise(
      resolve => {
        self.customHttpService.httpGET(true, true, 'v1/api/LiveStats/GetGeneralByLive', request).then(
          (response) => {
            resolve(response);
          }
        );
      }
    );
  }

  public getOffersByLive = (liveID: number) => {
    const self = this;
    const request = {
      liveID: liveID,
      Time: 1
    };

    return new Promise(
      resolve => {
        self.customHttpService.httpGET(true, true, 'v1/api/LiveStats/GetOffersByLive', request).then(
          (response) => {
            resolve(response);
          }
        );
      }
    );
  }
}
