import { Component, OnInit } from '@angular/core';
import { Images } from '@constants/images';
import { AccountService } from '@services/cms/account.service';
import { MatDialog } from '@angular/material/dialog';
import { FormAccountComponent } from '../form-account/form-account.component';
import { AccountClass } from '@classes/account.class';
import {FormOfferComponent} from "../../offer/form-offer/form-offer.component";

@Component({
  selector: 'app-index-account',
  templateUrl: './index-account.component.html',
  styleUrls: ['./index-account.component.scss']
})
export class IndexAccountComponent implements OnInit {
  public list: any;
  public title: string;

  constructor(
      public service: AccountService,
      public dialog: MatDialog,
      public images: Images
  ) {
    this.title = service.modelClass.langBase + 'title';
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {
    this.list = null;
    this.service.getAll().then((response) => {
      if (response) {

        this.list = this.service.data.data;
        // console.log(this.list);
        //this.list[0].logo = this.images.iconSamsung;
      }
    });
  }

  public openForm = () => {
    // console.log(this.service.modelClass.model);
    const dialogRef = this.dialog.open(FormAccountComponent, {
      width: '800px',
      data: this.service.modelClass.model
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data.reload) {
        this.loadData();
      }
    });
  }

  public newForm = () => {
    this.service.modelClass = new AccountClass();
    this.openForm();
  }

  public editForm = (account: any) => {
    if (account.length > 0) {
      this.service.modelClass.set(account[0]);
      this.openForm();
    }
  }

  public openShowComponent = (account: any) => {
    if (account.length > 0) {
      this.service.modelClass.set(account[0]);
      window.open('#/' + this.service.modelClass.route + '/' + this.service.modelClass.model[this.service.modelClass.idName], '_self');
    }
  }
}
