import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Images } from '@constants/images';
import { Poll } from '@models/poll';
import { PollService } from '@services/cms/poll.service';
import { PollClass } from '@classes/poll.class';
import { FormPollComponent } from '../form-poll/form-poll.component';
import {ShowPollComponent} from "../show-poll/show-poll.component";

@Component({
  selector: 'app-index-poll',
  templateUrl: './index-poll.component.html',
  styleUrls: ['./index-poll.component.scss']
})
export class IndexPollComponent implements OnInit {
  @Input() liveId = 0;
  public list: Poll[] | null = null;
  public title = 'admin.components.poll.title';
  public subtitle = 'admin.components.poll.subtitle';

  constructor(
      public service: PollService,
      public dialog: MatDialog,
      public images: Images
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {
    // console.log('TEST');
    this.list = null;
    if (this.liveId === 0) {
      this.service.getAll().then((response) => {
        if (response) {
          this.list = this.service.data.data;
          // console.log(this.list);
        }
      });
    } else {
      this.service.getByLiveID(this.liveId).then((response: any) => {
        if (response) {
          // console.log(this.service.data)
          this.list = this.service.data.data;
          // console.log(this.list);
        }
      });
    }
  }

  public openForm = () => {
    if (this.liveId > 0) {
      this.service.modelClass.model['liveID'] = this.liveId;
    }
    const dialogRef = this.dialog.open(FormPollComponent, {
      width: '800px',
      data: this.service.modelClass.model
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data.reload) {
        this.loadData();
      }
    });
  }

  public newForm = () => {
    this.service.modelClass = new PollClass();
    this.openForm();
  }

  public editForm = (poll: any) => {
    if (poll.length > 0) {
      this.service.modelClass.model = poll[0];
      this.openForm();
    }
  }

  public sendPoll = (poll: any) => {
    if (poll.length > 0) {
      this.service.sendPollToFront(poll[0].pollID).then((response: any) => {
        if (response) {
          // console.log(response);
        }
      });
    }
  }

  public openFormShow = (poll: any) => {
    if (poll.length > 0) {
      const dialogRef = this.dialog.open(ShowPollComponent, {
        width: '800px',
        data: poll[0]
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.data.reload) {
          // this.loadData();
        }
      });
    }
  }
}
