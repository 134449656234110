<div *ngIf="live !== null" class="admin-content-show-live">
    <div class="admin-content-header">
        <h1 class="admin-content-title">{{ title | translatePipe }}{{ ' ' + live.liveName }}</h1>
        <h2 class="admin-content-title-back" (click)="close()">Volver</h2>
    </div>
    <hr>
    <div class="admin-content-tab">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="home">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                   aria-selected="true">{{ 'admin.components.live.liveDetails.basicData' | translatePipe }}</a>
            </li>
            <li class="nav-item" role="scenes">
                <a class="nav-link" id="scenes-tab" data-toggle="tab" href="#scenes" role="tab" aria-controls="scenes"
                   aria-selected="false">{{ 'admin.components.live.liveDetails.scenes' | translatePipe }}</a>
            </li>
            <li class="nav-item" role="polls" *ngIf="isAdmin()">
                <a class="nav-link" id="polls-tab" data-toggle="tab" href="#polls" role="tab" aria-controls="polls"
                   aria-selected="false">{{ 'admin.components.live.liveDetails.polls' | translatePipe }}</a>
            </li>
            <li class="nav-item" role="hints" *ngIf="isAdmin()">
                <a class="nav-link" id="hints-tab" data-toggle="tab" href="#hints" role="tab" aria-controls="hints"
                   aria-selected="false">{{ 'admin.components.live.liveDetails.hints' | translatePipe }}</a>
            </li>
            <li class="nav-item" role="offers" *ngIf="isAdmin()">
                <a class="nav-link" id="offers-tab" data-toggle="tab" href="#offers" role="tab" aria-controls="offers"
                   aria-selected="false">{{ 'admin.components.live.liveDetails.offers' | translatePipe }}</a>
            </li>
            <!--li class="nav-item" role="chat">
                <a class="nav-link" id="chat-tab" data-toggle="tab" href="#chat" role="tab" aria-controls="chat"
                   aria-selected="false">{{ 'admin.components.live.liveDetails.chat' | translatePipe }}</a>
            </li-->
            <li class="nav-item" role="stats" *ngIf="isAdmin()">
                <a class="nav-link" id="stats-tab" data-toggle="tab" href="#stats" role="tab" aria-controls="stats"
                   aria-selected="false">{{ 'admin.components.live.liveDetails.stats' | translatePipe }}</a>
            </li>
            <!-- li class="nav-item" role="presentation">
                <a class="nav-link" id="preview-tab" data-toggle="tab" href="#preview" role="tab" aria-controls="profile"
                   aria-selected="false">Preview</a>
            </li -->
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="basic-info">
                    <div class="row col-12">
                        <div class="col-12 basic-margin">
                            <strong>{{ 'admin.components.live.liveName' | translatePipe }}:</strong> {{ live.liveName }}
                        </div>
                        <div class="row col-12">
                            <div class="col-8">
                                <strong>{{ 'admin.components.live.liveBroadcastID' | translatePipe }}:</strong> {{ live.liveBroadcastID }}
                            </div>
                            <div class="col-4">
                                <strong>{{ 'admin.components.live.liveDate' | translatePipe }}:</strong> {{ live.liveDate | date: 'y/MM/dd HH:mm:ss'}}
                            </div>
                        </div>
                        <!-- div class="col-12 basic-margin">
                            <strong>{{ 'admin.components.live.liveFrontVideoURL' | translatePipe }}:</strong><br> {{ live.liveFrontVideoURL }}
                        </div>
                        <div class="col-12 basic-margin">
                            <strong>{{ 'admin.components.live.countScenes' | translatePipe }}:</strong><br> {{ live.liveRTMP }}
                        </div -->
                        <div class="row col-12 basic-margin">
                            <div class="col-4">
                                <strong>{{ 'admin.components.live.countScenes' | translatePipe }}:</strong> {{ live.cantEscenas }}
                            </div>
                            <div class="col-4">
                                <strong>{{ 'admin.components.live.startLive' | translatePipe }}:</strong> {{ live.startDate }}
                            </div>
                            <div class="col-4">
                                <strong>{{ 'admin.components.live.stopLive' | translatePipe }}:</strong> {{ live.stopDate }}
                            </div>
                        </div>
                        <div class="row col-12">
                            <div class="col-3">
                                <strong>{{ 'admin.components.live.liveViews' | translatePipe }}:</strong> {{ live.liveViews }}
                            </div>
                            <div class="col-3">
                                <strong>{{ 'admin.components.live.liveClics' | translatePipe }}:</strong> {{ live.liveClics }}
                            </div>
                            <div class="col-3">
                                <strong>{{ 'admin.components.live.liveLikes' | translatePipe }}:</strong> {{ live.liveLikes }}
                            </div>
                            <div class="col-3">
                                <strong>{{ 'admin.components.live.liveShares' | translatePipe }}:</strong> {{ live.liveShares }}
                            </div>
                        </div>
                        <div class="row col-12" *ngIf="isAdmin()">
                            <div class="col-12 col-sm-6 col-md-3">
                                <button class="btn-icon-test" *ngIf="!live.livePlay" (click)="setAlert()">
                                    <p>{{ 'admin.components.live.liveDetails.startTestLive' | translatePipe }}</p>
                                    <span class="material-icons">play_arrow</span>
                                </button>
                                <button class="btn-icon-test" *ngIf="live.livePlay && !live.liveOnAir" (click)="setAlert()">
                                    <p>{{ 'admin.components.live.liveDetails.stopTestLive' | translatePipe }}</p>
                                    <span class="material-icons">stop</span>
                                </button>
                                <button class="btn-icon" *ngIf="!live.livePlay" (click)="setAlert(true)">
                                    <p>{{ 'admin.components.live.liveDetails.startLive' | translatePipe }}</p>
                                    <span class="material-icons">play_arrow</span>
                                </button>
                                <button class="btn-icon" *ngIf="live.livePlay && live.liveOnAir" (click)="setAlert(true)">
                                    <p>{{ 'admin.components.live.liveDetails.stopLive' | translatePipe }}</p>
                                    <span class="material-icons">stop</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <h1 class="title">Html - Script</h1>
                <hr>
                <div class="code-body">
                <pre>
                    <code [highlight]="htmlCode" ></code>
                </pre>
                </div>
            </div>
            <div class="tab-pane fade" id="scenes" role="tabpanel" aria-labelledby="scenes-tab">
                <app-index-scene [live]="live"></app-index-scene>
            </div>
            <div class="tab-pane fade" id="offers" role="tabpanel" aria-labelledby="offers-tab" *ngIf="isAdmin()">
                <app-index-offer [liveId]="live.liveID"></app-index-offer>
            </div>
            <div class="tab-pane fade" id="polls" role="tabpanel" aria-labelledby="polls-tab" *ngIf="isAdmin()">
                <app-index-poll [liveId]="live.liveID"></app-index-poll>
            </div>
            <div class="tab-pane fade" id="hints" role="tabpanel" aria-labelledby="hints-tab" *ngIf="isAdmin()">
                <app-index-hint [liveID]="live.liveID"></app-index-hint>
            </div>
            <!-- div class="tab-pane fade" id="chat" role="tabpanel" aria-labelledby="chat-tab">
                <app-card-chat [live]="live" *ngIf="live !== null"></app-card-chat>
            </div-->
            <div class="tab-pane fade" id="stats" role="tabpanel" aria-labelledby="stats-tab" *ngIf="isAdmin()">
                <app-stats-live [live]="live"></app-stats-live>
            </div>
            <!-- div class="tab-pane fade" id="preview" role="tabpanel" aria-labelledby="preview-tab">
                <ng-amp
                        *ngIf="liveVideoUrl !== ''"
                        src="{{ liveVideoUrl }}"
                        [controls]="true"
                        [autoplay]="true"
                        [fluid]="false">
                </ng-amp>
            </div -->
        </div>
    </div>
    <app-alert
            *ngIf="showAlert"
            [title]="titleAlert"
            [type]="'action'"
            [description]="descriptionAlert"
            [success]="'warning'"
            [primaryButton]="'ok'"
            [secondaryButton]="'cancel'"
            (primaryAction)="playStopLive($event)"
    ></app-alert>
</div>
