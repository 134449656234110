import { Injectable } from '@angular/core';
import { CustomHttpService } from './generals/customHttp.service';
import { AbstractClass } from '@classes/abstract.class';

@Injectable({ providedIn: 'root' })
export abstract class AbstractService {
    public version = 'v1';
    public modelList: AbstractClass[] = [];
    public data: any;
    public base = '/api/';

    constructor(
        public customHttpService: CustomHttpService,
        public modelClass: AbstractClass
    ) { }

    public getAll(): Promise<any>{
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }

    public getByID = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/' + id).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Función que inserta el Modelo */
    public create = () => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(
                    true,
                    true,
                    url + this.modelClass.modelName,
                    self.modelClass.getJsonBase()
                ).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Funcion que actualiza el Modelo */
    async update(id: number): Promise<any> {
        const self = this;
        const url = self.version + self.base;
        return await new Promise(
            resolve => {
                self.customHttpService.httpPUT(
                    true,
                    true,
                    url + this.modelClass.modelName + '/' + id,
                    this.modelClass.getJsonUpdate()
                ).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(true);
                    }
                );
            }
        );
    }

    public getModelList(): AbstractClass[] { return this.modelList; }

    public changeStateCustomer = (customer: any) => {

    }
}

export interface ResponseVar {
    data: any;
    success: boolean;
}
