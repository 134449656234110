<div class="card card-popup-form" *ngIf="service !== undefined && showForm">
    <form ngNativeValidate>
        <div class="card-header">
            <h1 class="title">{{ subtitle | translatePipe }}</h1>
            <img class="img-close" src="{{ imageClose }}" alt="img_close" (click)="close()">
        </div>
        <div class="card-body">
            <div class="row">
                <app-field-form
                        class="form-input col-12 col-md-6"
                        [service]="service"
                        [name]="'liveID'"
                        [cellType]="'liveID'"
                        [label]="'live'"
                        [disable]="disableInputLiveID"
                        [required]="true" >
                </app-field-form>
                <app-field-form
                        class="form-input col-12 col-md-6"
                        [service]="service"
                        [name]="'escenaID'"
                        [cellType]="'sceneID'"
                        [label]="'scene'"
                        [disable]="false"
                        [required]="true" >
                </app-field-form>
                <app-field-form
                        class="form-input col-12"
                        [service]="service"
                        [name]="'title'"
                        [cellType]="'text'"
                        [label]="'title'"
                        [disable]="false"
                        [required]="true" >
                </app-field-form>
                <app-field-form
                        class="form-input col-12"
                        [service]="service"
                        [name]="'subtitle'"
                        [cellType]="'text'"
                        [label]="'subtitle'"
                        [disable]="false"
                        [required]="true" >
                </app-field-form>
                <app-field-form
                        class="form-input col-12"
                        [service]="service"
                        [name]="'description'"
                        [cellType]="'textarea'"
                        [label]="'description'"
                        [disable]="false"
                        [required]="true" >
                </app-field-form>
                <app-field-form
                        class="form-input col-12 col-md-6"
                        [service]="service"
                        [name]="'isActive'"
                        [cellType]="'active'"
                        [label]="'isActive'"
                        [disable]="false"
                        [required]="true" >
                </app-field-form>
                <app-review-question-form
                        class="col-12"
                        [service]="service"
                ></app-review-question-form>
                <div class="col-12">
                    <p class="error" *ngIf="error">{{ 'commons.forms.errorFields' | translatePipe }}<b *ngFor="let error of errors">{{ service.modelClass.langBase + error | translatePipe }}</b></p>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-12 col-md-6"></div>
                <div class="col-12 col-md-3"><app-button [type]="'button'" [classCss]="'btn-cancel'" (action)="close()" [text]="'commons.cancel'"></app-button></div>
                <div class="col-12 col-md-3"><app-button [type]="'button'" [classCss]="''" (action)="sendForm()" [text]="'commons.accept'"></app-button></div>
            </div>
        </div>
    </form>
</div>
