import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-live',
  templateUrl: './info-live.component.html',
  styleUrls: ['./info-live.component.css']
})
export class InfoLiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
