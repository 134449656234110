import {Component, Input, OnInit} from '@angular/core';
import { Images } from '@constants/images';
import { MatDialog } from '@angular/material/dialog';
import { TopicService } from '@services/cms/topic.service';
import { FormTopicComponent } from '../form-topic/form-topic.component';
import { TopicClass } from '@classes/topic.class';

@Component({
  selector: 'app-index-topic',
  templateUrl: './index-topic.component.html',
  styleUrls: ['./index-topic.component.scss']
})
export class IndexTopicComponent implements OnInit {
  @Input() accountId = 0;
  public list: any;
  public title: string;

  constructor(
      public service: TopicService,
      public dialog: MatDialog,
      public images: Images
  ) {
    this.title = service.modelClass.langBase + 'title';
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {
    this.list = null;
    if (this.accountId === 0) {
      this.service.getAll().then((response) => {
        if (response) {
          this.list = this.service.data.data;
        }
      });
    } else {
      this.service.getByAccountID(this.accountId).then((response: any) => {
        if (response) {
          this.list = this.service.data.data;
        }
      });
    }
  }

  public openForm = () => {
    if (this.accountId > 0) {
      this.service.modelClass.model['accountID'] = this.accountId;
    }
    const dialogRef = this.dialog.open(FormTopicComponent, {
      width: '800px',
      data: this.service.modelClass.model
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data.reload) {
        this.loadData();
      }
    });
  }

  public newForm = () => {
    this.service.modelClass = new TopicClass();
    this.openForm();
  }

  public editForm = (account: any) => {
    if (account.length > 0) {
      this.service.modelClass.set(account[0]);
      this.openForm();
    }
  }

  public openShowComponent = (account: any) => {
    if (account.length > 0) {
      this.service.modelClass.set(account[0]);
      window.open('#/' + this.service.modelClass.route + '/' + this.service.modelClass.model[this.service.modelClass.idName], '_self');
    }
  }
}
