import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Topic } from '@models/topic';
import { TopicService } from '@services/cms/topic.service';

@Component({
  selector: 'app-show-topic',
  templateUrl: './show-topic.component.html',
  styleUrls: ['./show-topic.component.scss']
})
export class ShowTopicComponent implements OnInit {
  private id: any | null;
  public title: string;
  public topic: Topic | null = null;
  public countLives:string = '';

  constructor(
      public service: TopicService,
      private activatedRoute: ActivatedRoute,
  ) {
    this.title = this.service.modelClass.langBase + 'singularTitle';
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    // console.log(this.id);
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {
    this.topic = null;
    // console.log(this.topic);
    this.service.getByID(this.id).then((result) => {
      if (result) {
        this.countLives = this.service.data.data.countLives;
        this.topic = this.service.data.data;
        // console.log(this.topic);
      }
    });
  }

  public close = () => {
    history.back();
  }
}
