<div *ngIf="topic !== null">
    <div class="admin-content-header">
        <h1 class="admin-content-title">{{ title | translatePipe }}{{ ' ' + topic.name }}</h1>
        <h2 class="admin-content-title-back" (click)="close()">Volver</h2>
    </div>
    <hr>
    <div class="admin-content-tab">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="home"
                   aria-selected="true">{{ service.modelClass.langBase + 'basicData' | translatePipe }}</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="offers-tab" data-toggle="tab" href="#offers" role="tab" aria-controls="profile"
                   aria-selected="false">{{ service.modelClass.langBase + 'lives' | translatePipe }}</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="basic" role="tabpanel" aria-labelledby="home-tab">
                <div class="basic-info">
                    <div class="row col-12">
                        <div class="col-12 basic-margin">
                            <strong>{{ service.modelClass.langBase + 'name' | translatePipe }}:</strong> {{ topic.name }}
                        </div>
                        <div class="row col-12 basic-margin">
                            <div class="col-6">
                                <strong>{{ service.modelClass.langBase + 'email' | translatePipe }}:</strong> {{ topic.email }}
                            </div>
                            <div class="col-6 basic-margin">
                                <strong>{{ service.modelClass.langBase + 'isActive' | translatePipe }}:</strong> {{ topic.isActive }}
                            </div>

                        </div>
                        <div class="row col-12">
                            <div class="col-6 basic-margin">
                                <strong>{{ service.modelClass.langBase + 'adminMSGColor' | translatePipe }}:</strong> {{ topic.adminMSGColor }}
                            </div>
                            <div class="col-6 basic-margin">
                                <strong>{{ service.modelClass.langBase + 'adminBGColor' | translatePipe }}:</strong>{{ topic.adminBGColor }}
                            </div>
                            <div class="col-6 basic-margin">
                                <strong>{{ service.modelClass.langBase + 'createdBy' | translatePipe }}:</strong>{{ topic.createdBy }}
                            </div>
                            <div class="col-12 basic-margin">
                                <strong>{{ service.modelClass.langBase + 'createdAt' | translatePipe }}:</strong>{{ topic.createdAt }}
                            </div>
                            <div class="col-12 basic-margin">
                                <strong>{{ service.modelClass.langBase + 'liveBroadcastID' | translatePipe }}:</strong> {{ topic.liveBroadcastID }}
                            </div>

                        </div>

                        <div class="row" id="stats">

                            <h1 class="title">Estadisticas Generales</h1>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <app-card-stats-number [title]="'Total lives'" [value]="countLives"></app-card-stats-number>
                            </div>

                    </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="offers" role="tabpanel" aria-labelledby="offers-tab">
                <app-index-live [topic]="topic"></app-index-live>
            </div>
        </div>
    </div>
</div>
