import { Component, Input , OnInit } from '@angular/core';
import { Topic } from '@models/topic';
import { AbstractService } from '@services/Abstract.service';
import { TopicService } from '@services/cms/topic.service';

@Component({
  selector: 'app-input-picker-color',
  templateUrl: './input-picker-color.component.html',
  styleUrls: ['./input-picker-color.component.css']
})
export class InputPickerColorComponent implements OnInit {
  @Input() public serviceModel: AbstractService | null = null;
  @Input() public label = '';
  @Input() public name = '';
  @Input() public required = false;
  @Input() public multiple = false;
  @Input() public disabled = false;



  constructor(
    public service:TopicService
  ) { }

  ngOnInit(): void {
 
  }

  color(){    
  }

}
