 <div class="card">
  <!--   
    <mat-accordion class="example-headers-align" multi *ngFor="let spec of data">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
          {{spec}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-table-list></app-table-list>

      </mat-expansion-panel>
    </mat-accordion> -->

    <mat-list>
      <mat-list-item (click)="onclick(spec)" *ngFor="let spec of data"> <p>{{spec}}</p> 
        <img class="icon-arrow" src="{{arrowRight}}">
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
    
  </div> 

  
