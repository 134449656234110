import { Component, Input, OnInit } from '@angular/core';
import { Live } from '@models/live';
import { LiveStatsService } from '@services/cms/live-stats.service';
import { DatePipe } from '@angular/common';
// import {TranslatePipe} from "@pipes/translate";

@Component({
  selector: 'app-stats-live',
  templateUrl: './stats-live.component.html',
  styleUrls: ['./stats-live.component.scss']
})
export class StatsLiveComponent implements OnInit {
  @Input() public live: Live | null = null;
  public minutes = 1;
  public dateReport = '';
  public clicksByOffert: any[] = [];
  public totalOffers: any;
  saleData = [
    { name: 'Mobiles', value: 105000 },
    { name: 'Laptop', value: 55000 },
    { name: 'AC', value: 15000 },
    { name: 'Headset', value: 150000 },
    { name: 'Fridge', value: 20000 }
  ];
  public userResponse: any = null;
  public userResponseSum: any = null;
  public usersPerTime: any[] = [];
  public usersPerTimeSum: any[] = [];
  public messagesResponse: any = null;
  public messagesPerTime: any[] = [];
  public messagesPerTimeSum: any[] = [];
  public liveStatsResponse: any = null;
  public interactions = '0';
  public averageTime = '0';
  public averageTimeSum = '0';
  public timeLine = 1;
  view: [number, number] = [0, 0];

  public colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  // END TEST

  constructor(
      public service: LiveStatsService,
      private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {

    if (this.live !== null) {
      this.dateReport = this.datePipe.transform(new Date(), 'y/MM/dd hh:mm') ?? '';
      this.usersPerTime = [];
      this.service.getUsersByLiveTime(this.live.liveID, this.timeLine).then((data: any) => {
        if (data !== false) {
          this.mapReportUsersSum(data);
          const multi: { name: string, series: { name: string, value: number }[] } = {
            name: 'Usuarios Conectados',
            series: []
          };
          this.userResponse = data.data;
          const series = this.userResponse.reportDates;
          series.map((item: { date: string, users: number }) => {
            const date = this.datePipe.transform(item.date, 'HH:mm');
            multi.series.push({
              name: date ?? '',
              value: item.users
            });
          });
          const avg: string = '01/01/01 ' +  this.userResponse.averageConnection ?? '00:00:00';
          this.averageTime = this.datePipe.transform(avg, 'HH:mm:ss') ?? '';
          this.usersPerTime.push(multi);
          // console.log(this.userResponse);
        }
      });
      this.messagesPerTime = [];
      this.messagesPerTimeSum = [];
      this.service.getMessagesByLiveTime(this.live.liveID, this.timeLine).then((data: any) => {
        if (data !== false) {
          const multi: { name: string, series: { name: string, value: number }[] } = {
            name: 'Mensajes enviados',
            series: []
          };
          const multiSum: { name: string, series: { name: string, value: number }[] } = {
            name: 'Mensajes enviados',
            series: []
          };
          this.messagesResponse = data.data;
          const series = this.messagesResponse.reportDates;
          series.map((item: { date: string, data: number }) => {
            const date = this.datePipe.transform(item.date, 'hh:mm');
            multi.series.push({
              name: date ?? '',
              value: item.data
            });
          });
          this.messagesPerTime.push(multi);
          const seriesSum = this.messagesResponse.reportDatesSum;
          seriesSum.map((item: { date: string, data: number }) => {
            const date = this.datePipe.transform(item.date, 'hh:mm');
            multiSum.series.push({
              name: date ?? '',
              value: item.data
            });
          });
          this.messagesPerTimeSum.push(multiSum);
          // console.log(this.messagesPerTimeSum);
        }
      });
      this.service.getGeneralByLive(this.live.liveID).then((data: any) => {
        if (data != null) {
          this.liveStatsResponse = data.data;
          const interactions = (this.liveStatsResponse.totalInteractions) / this.liveStatsResponse.liveUsers;
          const factor = 10 ** 2;
          const rounded = Math.round(interactions * factor) / factor;
          this.interactions = rounded + '';
          const time = '01/01/01 ' + this.liveStatsResponse.totalTime;
          this.liveStatsResponse.totalTime =  this.datePipe.transform(time, 'HH:mm:ss');
          // console.log(this.liveStatsResponse);
        }
      });
      // console.log(this.live.liveID);
      this.service.getOffersByLive(this.live.liveID).then((data: any) => {
        if (data != null) {
          this.totalOffers = data.data.totalOffers;
          this.mapOfferts(data);
        }
      });
    }
  }



  public mapOfferts = (data: any) => {
    this.clicksByOffert = [];
    const ofertas = data.data.offers;
    ofertas.map((item: { productName: string , offertClics: number}) => {
       this.clicksByOffert.push({
        name: item.productName,
        value: item.offertClics
      });
    });
  }



  public mapReportUsersSum = (data: any) => {
    const multi: { name: string, series: { name: string, value: number }[] } = {
      name: 'Usuarios Conectados',
      series: []
    };
    this.userResponseSum = data.data;
    // console.log(this.userResponseSum.reportDatesSum);
    const series = this.userResponseSum.reportDatesSum;
    series.map((item: { date: string, users: number }) => {
      const date = this.datePipe.transform(item.date, 'hh:mm');
      multi.series.push({
        name: date ?? '',
        value: item.users
      });
    });
    const avg: string = '01/01/01 ' +  this.userResponseSum.averageConnection ?? '00:00:00';
    this.averageTimeSum = this.datePipe.transform(avg, 'HH:mm:ss') ?? '';
    this.usersPerTimeSum.push(multi);
  }

}
