import { createFeatureSelector, createSelector } from '@ngrx/store';
import { counterFeatureKey, AuthPartialState, Languages } from '../reducers/language.reducer';

export const getAuthState = createFeatureSelector<AuthPartialState, Languages>(
  counterFeatureKey
);

export const selectLanguageState = createSelector(getAuthState, state => state);

export const authQuery = {
  selectLanguageState
};
