import { Injectable } from '@angular/core';
import { AbstractClass } from './abstract.class';
import { Live } from '@models/live';

@Injectable({
  providedIn: 'root'
})
export class LiveClass extends AbstractClass{
  model: Live;
  modelName = 'Lives';
  langBase = 'admin.components.live.';
  idName = 'liveID';
  formCells = [
    // tslint:disable-next-line:max-line-length
    { name: 'accountID', label: 'account', cellType: 'accountID', disable: false, required: true, cols: '6', order: 1, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'topicID', label: 'topic', cellType: 'topicID', disable: false, required: true, cols: '6', order: 2, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'liveName', label: 'liveName', cellType: 'text', disable: false, required: true, cols: '6', order: 3, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'liveDate', label: 'liveDate', cellType: 'date', disable: false, required: true, cols: '6', order: 4, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'themeID', label: 'theme', cellType: 'themeID', disable: false, required: true, cols: '6', order: 5, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'shareURL', label: 'shareURL', cellType: 'string', disable: false, required: true, cols: '6', order: 6, multiple: false },
    // tslint:disable-next-line:max-line-length
    // { name: 'adminBGColor', label: 'adminBGColor', cellType: 'color-live', disable: false, required: false, cols: '6', order: 2, multiple: false },
    // tslint:disable-next-line:max-line-length
    // { name: 'adminMSGColor', label: 'adminMSGColor', cellType: 'color-live', disable: false, required: false, cols: '6', order: 2, multiple: false },
    // tslint:disable-next-line:max-line-length
    // { name: 'adminTextColor', label: 'adminTextColor', cellType: 'color-text', disable: false, required: false, cols: '6', order: 2, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'haveWhatsapp', label: 'haveWhatsapp', cellType: 'active', disable: false, required: false, cols: '6', order: 7, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'whatsappNumber', label: 'whatsappNumber', cellType: 'string', disable: false, required: false, cols: '6', order: 8, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'havePhoneNumber', label: 'havePhoneNumber', cellType: 'active', disable: false, required: false, cols: '6', order: 9, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'phoneNumber', label: 'phoneNumber', cellType: 'string', disable: false, required: false, cols: '6', order: 10, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'haveTD', label: 'haveTD', cellType: 'active', disable: false, required: false, cols: '6', order: 11, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'urlTD', label: 'urlTD', cellType: 'string', disable: false, required: false, cols: '6', order: 12, multiple: false }
  ];
  formTabs = [];
  formAccordion = [];
  tableCells = [
    { name: 'topic.account.name', label: 'account', cellType: 'text', order: false, download: true },
    { name: 'topic.name', label: 'topic', cellType: 'text', order: false, download: true },
    { name: 'liveName', label: 'liveName', cellType: 'text', order: false, download: true },
    { name: 'cantEscenas', label: 'countScenes', cellType: 'text', order: false, download: true },
    { name: 'liveClics', label: 'liveClics', cellType: 'text', order: false, download: true },
    { name: 'liveViews', label: 'liveViews', cellType: 'text', order: false, download: true },
    { name: 'liveLikes', label: 'liveLikes', cellType: 'text', order: false, download: true },
    { name: 'liveShares', label: 'liveShares', cellType: 'text', order: false, download: true },
    { name: 'liveDate', label: 'liveDate', cellType: 'date', order: false, download: true }
  ];
  route = 'admin/live';
  stringsToRemove = ['liveBroadcastID'];

  constructor()
  {
    super();
    this.model = {
      liveID: 0,
      topicID: 0,
      topic: null,
      liveBroadcastID: '',
      cantEscenas: 0,
      startDate: new Date(),
      stopDate: new Date(),
      liveDate:	new Date(),
      liveClics: 0,
      liveViews: 0,
      liveLikes: 0,
      liveShares: 0,
      liveName: '',
      liveRTMP:	'',
      livePlay: false,
      liveOnAir: false,
      haveTD: false,
      urlTD: '',
      shareURL: '',
      themeID: 0,
      theme: null,
      adminBGColor: '#000000',
      adminMSGColor: '#000000',
      adminTextColor: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      havePhoneNumber: false,
      haveWhatsapp: false,
      phoneNumber: '',
      whatsappNumber: '',
      month: 0,
      week: 0,
      year: 0,
      createdBy: '',
      escenas: []
    };
  }
}
