<!-- div *ngIf="service !== null">
    <label class="input_label mt-2">{{ service.modelClass.langBase + label | translatePipe }}</label>
    <div class="custom-dropzone mt-2" ngx-dropzone [accept]="'image/*'" (change)="onFilesAdded($event, name)">
        <ngx-dropzone-label>
            <div>
                <img src="{{ images.iconPlusCircle }}" class="center-content-width">
                <h2>Selecciona los archivos</h2>
                <label>Suelta el archivo aqui.</label>
                <br>
                <label>Tamaño maximo 20mb.</label>
            </div>
        </ngx-dropzone-label>
        <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of filesImage" [file]="f"
                                    [removable]="true" (removed)="onRemoveMultiple(f, name)">
            <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-image-preview>
    </div>
</div -->
<div *ngIf="service !== null">
    <label class="input_label mt-2">{{ service.modelClass.langBase + label | translatePipe }}</label>
    <input type="file" [name]="name" [multiple]="multiple" [required]="required" [accept]="'image/*'" (change)="onFilesAdded($event)">
</div>
