import { Component, Input, OnInit } from '@angular/core';
import { LiveService } from '@services/cms/live.service';
import { Live } from '@models/live';
import { ActivatedRoute } from '@angular/router';
import { HubService } from "@services/hub/hub.service";
declare var configurations: any;

@Component({
  selector: 'app-show-live',
  templateUrl: './show-live.component.html',
  styleUrls: ['./show-live.component.scss']
})
export class ShowLiveComponent implements OnInit {
  private id: any | null;
  public title = '';
  public live: Live | null = null;
  private socketConnected = false;
  public htmlCode = '';
  // Alert Text
  public titleAlert = '';
  public descriptionAlert = '';
  // Variable Alert
  public showAlert = false;
  public url_front_script = configurations.url_front_script;
  public userRolesAuth: number[] = [1,2];
  public userRole: string;

  // @Input() data: any | null = null;

  constructor(
      public service: LiveService,
      private activatedRoute: ActivatedRoute,
      private hubService: HubService
  ) {
    this.title = this.service.modelClass.langBase + 'singularTitle';
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.userRole = sessionStorage.getItem('role') || '0';
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {
    this.live = null;
    this.service.getByID(this.id).then((result) => {
      if (result) {
        this.live = this.service.data.data;
        const group = this.live?.liveBroadcastID ?? '';
        if (!this.socketConnected) {
          this.hubService.joinToGroup(group);
          this.socketConnected = true;
        }
        if (this.live !== null) {
          this.htmlCode =
              `<section id="stream-container"></section>
<script>
    let iFrame = document.createElement("iframe");
    iFrame.setAttribute("src", "` + this.url_front_script + `?live=` + this.live.liveBroadcastID + `");
    iFrame.width = "100%";
    iFrame.height = "825px";
    iFrame.style = "overflow:hiden; border:none;"
    let container = document.getElementById('stream-container');
    container.appendChild(iFrame);
</script>`;
        }
      }
    });
    this.showAlert = false;
  }

  public isAdmin = (): boolean => this.userRolesAuth.includes(Number(this.userRole));

  public close = () => {
    history.back();
  }

  public playStopLive = (r: boolean) => {
    if (r) {
      if (this.live !== null) {
        if (!this.live.livePlay) {
          this.service.playLive(this.live.liveID, this.live.liveOnAir).then((data: any) => {
            this.loadData();
          });
        } else {
          this.service.stopLive(this.live.liveID, this.live.liveOnAir).then((data: any) => {
            this.loadData();
          });
        }
      }
    } else {
      this.showAlert = false;
    }
  }

  public setAlert = (liveOnAir: boolean = false) => {
    if (this.live !== null) {
      this.live.liveOnAir = liveOnAir;
      if (!this.live.livePlay) {
        this.titleAlert = 'commons.alert.live.play';
        this.descriptionAlert = 'commons.alert.live.playDescription';
      } else {
        this.titleAlert = 'commons.alert.live.stop';
        this.descriptionAlert = 'commons.alert.live.stopDescription';
      }
      this.showAlert = true;
    }
  }
}
