import {Component, Input, OnInit} from '@angular/core';
import { Images } from '@constants/images';
import { MatDialog } from '@angular/material/dialog';
import { FormHintComponent } from '../form-hint/form-hint.component';
import { HintService } from "@services/cms/hint.service";
import { HintClass } from '@classes/hint.class';

@Component({
  selector: 'app-index-hint',
  templateUrl: './index-hint.component.html',
  styleUrls: ['./index-hint.component.scss']
})
export class IndexHintComponent implements OnInit {
  @Input() liveID = 0;
  public list: any;
  public title: string;

  constructor(
      public service: HintService,
      public dialog: MatDialog,
      public images: Images
  ) {
    this.title = service.modelClass.langBase + 'title';
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {
    this.list = null;
    if (this.liveID === 0) {
      this.service.getAll().then((response) => {
        if (response) {
          this.list = this.service.data.data;
        }
      });
    } else {
      /* this.service.postTest2(this.liveID).then((response: any) => {
        if (response) {
          // console.log(this.service.data.data);
          this.list = this.service.data.data;
        }
      }); */
      /* this.service.postTest(this.liveID).then((response: any) => {
        if (response) {
          // console.log(this.service.data.data);
          this.list = this.service.data.data;
        }
      }); */

      this.service.getByLiveID(this.liveID).then((response: any) => {
        if (response) {
          // console.log(this.service.data.data);
          this.list = this.service.data.data;
        }
      });
    }
  }

  public openForm = () => {
    if (this.liveID > 0) {
      this.service.modelClass.model['liveID'] = this.liveID;
    }
    const dialogRef = this.dialog.open(FormHintComponent, {
      width: '800px',
      data: this.service.modelClass.model
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data.reload) {
        this.loadData();
      }
    });
  }

  public newForm = () => {
    this.service.modelClass = new HintClass();
    this.openForm();
  }

  public editForm = (hint: any) => {
    if (hint.length > 0) {
      this.service.modelClass.set(hint[0]);
      this.openForm();
    }
  }

  public sendHint = (hint: any) => {
    if (hint.length > 0) {
      this.service.sendHintToFront(hint[0].hintID).then((response: any) => {
        if (response) {
          // console.log(response);
        }
      });
    }
  }
}
