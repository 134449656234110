<div class="login">
  <div class="card">
    <div class="card-body">
      <div class="div-auth">
        <div class="div-language">
          <app-select-language></app-select-language>
        </div>
        <h1>{{ title | translatePipe }}</h1>
        <!-- img src="{{ images.logo }}" alt="logo" class="logo" -->
        <div class="content-login">
          <form ngNativeValidate (ngSubmit)="login()" class="row">
            <mat-form-field appearance="standard" class="col-12">
              <input matInput placeholder="{{ 'auth.userName' | translatePipe }}" [(ngModel)]="userName" name="userName" type="text" required>
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-12">
              <input matInput placeholder="{{ 'auth.password' | translatePipe }}" [(ngModel)]="password" name="password" type="{{ showPassword ? 'text' : 'password' }}" required>
              <p (click)="showPassword = !showPassword" class="show">{{ 'auth.showPassword' | translatePipe }}</p>
            </mat-form-field>
            <div class="col-12">
              <div class="row div-pass-recovery">
                <div class="col-12 col-md-6"></div>
                <div class="col-12 col-md-6">
                  <!-- a (click)="openDialog()" class="float-right">{{ 'auth.passwordRecoveryLink' | translatePipe }}</a -->
                </div>
              </div>
            </div>
            <app-button class="col-12" type="submit" [classCss]="'login'" [text]="'auth.login'"></app-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
