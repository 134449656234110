import { Injectable } from '@angular/core';
import { AbstractClass } from './abstract.class';
import { Scene } from '@models/scene';

@Injectable({
  providedIn: 'root'
})
export class ScenesClass extends AbstractClass{
  model: Scene;
  modelName = 'Escenas';
  langBase = 'admin.components.scene.';
  idName = 'escenaID';
  formCells = [
    // tslint:disable-next-line:max-line-length
    { name: 'liveID', label: 'live', cellType: 'liveID', disable: false, required: true, cols: '6', order: 1, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'nombreEscena', label: 'name', cellType: 'text', disable: false, required: true, cols: '6', order: 1, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'subtituloEscena', label: 'subtitle', cellType: 'text', disable: false, required: true, cols: '6', order: 1, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'descripcionEscena', label: 'description', cellType: 'text', disable: false, required: true, cols: '6', order: 1, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'order', label: 'order', cellType: 'number', disable: false, required: true, cols: '6', order: 1, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'photo', label: 'image', cellType: 'image', disable: false, required: false, cols: '6', order: 2, multiple: false }
  ];
  formTabs = [];
  formAccordion = [];
  tableCells = [
    { name: 'imagenEscena', label: 'image', cellType: 'image', order: false, download: true },
    { name: 'live.liveName', label: 'live', cellType: 'text', order: false, download: true },
    { name: 'nombreEscena', label: 'name', cellType: 'text', order: false, download: true },
    { name: 'subtituloEscena', label: 'subtitle', cellType: 'text', order: false, download: true },
    { name: 'descripcionEscena', label: 'description', cellType: 'text', order: false, download: true },
    { name: 'escenaRTMP', label: 'sceneRTMP', cellType: 'text', order: false, download: true },
    { name: 'order', label: 'order', cellType: 'number', order: false, download: true },
    { name: 'clicsEscena', label: 'clicks', cellType: 'number', order: false, download: true },
    { name: 'createdAt', label: 'createdAt', cellType: 'date', order: false, download: true },
    { name: 'createdBy', label: 'createdBy', cellType: 'text', order: false, download: true }
  ];
  route = 'admin/scene';
  stringsToRemove = ['escenaBroadcastID', 'escenaRTMP',
  'escenaTotalViews', 'escenaUniqueName', 'escenaViews', 'isActive',
  'isActive', 'live', 'liveFrontVideoURL', 'liveFrontVideoURLIPhone',
  'month', 'week', 'year'];

  constructor()
  {
    super();
    this.model = {
      nombreEscena: '',
      subtituloEscena: '',
      descripcionEscena: '',
      escenaID: 0,
      escenaBroadcastID: '',
      escenaRTMP: '',
      escenaTotalViews: 0,
      escenaUniqueName: '',
      escenaViews: 0,
      isActive: true,
      live: null,
      imagenEscena: '',
      clicsEscena: 0,
      escenaFrontVideoURL: '',
      escenaFrontVideoURLIPhone: '',
      liveID: 0,
      month: 0,
      order: 0,
      week: 0,
      year: 0,
      createdAt: new Date(),
      updatedAt: new Date(),
      createdBy: ''
    };
  }
}
