import { Injectable } from '@angular/core';
import { CustomHttpService } from '@services/generals/customHttp.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public customHttpService: CustomHttpService,
  ) { }

  public login = (userName: string, password: string) => {
    const self = this;
    const request = {
      email: userName,
      password
    };
    return new Promise(
      resolve => {
        self.customHttpService.httpPOST(true, true, 'v1/api/Auth/AuthCMS', request).then(
          (response: any) => {
            if (response === false) { resolve(response); }
            else {
              sessionStorage.setItem('token', response.session.token);
              sessionStorage.setItem('email', response.session.user.email);
              sessionStorage.setItem('role', response.session.user.roleGroupID);
              sessionStorage.setItem('role_name', response.session.user.roleGroupID.roleGroupName);
              resolve(response);
            }
          }
        );
      }
    );
  }

  public logout = () => {
    sessionStorage.clear();
  }

  public isAuthenticated = (): boolean => {
    return !!sessionStorage.getItem('token');
  }

}
