import { Injectable } from '@angular/core';
import { CustomHttpService } from '@services/generals/customHttp.service';
import { AbstractService } from '@services/Abstract.service';
import { HintClass } from '@classes/hint.class';

@Injectable({
    providedIn: 'root'
})
export class HintService extends AbstractService {

    constructor(
        public customHttpService: CustomHttpService
    ) {
        super(customHttpService, new HintClass());
    }

    public getByID = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/GetByID/' + id).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }

    public getByLiveID = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/GetByLiveID/' + id).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }

    public postTest = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        const request = {
            app: 280
        }
        return new Promise(
            resolve => {
                self.customHttpService.httpPOSTURL(true, true, 'https://live.connect.cheil.com.co/api/log_users?app=280').then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }

    public postTest2 = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        const request = {
            app: 256
        }
        return new Promise(
            resolve => {
                self.customHttpService.httpGETURL(true, true, 'https://api.sesfrm.samsung.com.co/v1/api/FrmNDA').then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }

    public getHintTypes = () => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/GetHintTypes').then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(response);
                    }
                );
            }
        );
    }

    public sendHintToFront = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpPATCH(true, true, url + this.modelClass.modelName + '/SendHintToFront/' + id).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }
}
