import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuFacade } from '@redux/menu.facade';
import { User } from '@models/user';
import { Images } from '@constants/images';
import { AuthService } from "@services/auth/auth.service";
import {HubService} from "@services/hub/hub.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  public listTitles: any[] = [];
  location: Location;
  // tslint:disable-next-line:variable-name
  mobile_menu_visible: any = 0;
  private toggleButton: any;
  private sidebarVisible: boolean;
  private layer: any;
  public menuLarge = false;
  public email: string = '';
  // @ts-ignore
  public unsubscribeMenu: Subscription;
  // public user: User;
  // public arrowBottom = Images.iconArrowBottom;

  constructor(
    location: Location,
    public menuFacade: MenuFacade,
    public authService: AuthService,
    public images: Images,
    public hubService: HubService
  ) {
    this.location = location;
    this.sidebarVisible = false;
    this.email = sessionStorage.getItem('email') || '';
    const self = this;
    self.unsubscribeMenu = self.menuFacade.menuState$.subscribe(
      ok => {
        self.menuLarge = ok.open;
      }
    );
    // const userString = sessionStorage.getItem('session') || '';
    // this.user = JSON.parse(userString);
  }

  // tslint:disable-next-line:typedef
  ngOnInit() {

    /*
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        this.router.events.subscribe((event) => {
          this.sidebarClose();
          this.layer = document.getElementsByClassName('close-layer')[0];
          if (this.layer) {
            this.layer.remove();
            this.mobile_menu_visible = 0;
          }
        }); */
    setTimeout(() => { this.hubService.joinToGroup(this.email) }, 10000);
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    const self = this;
    self.unsubscribeMenu.unsubscribe();
    this.hubService.leaveToGroup(this.email);
    this.hubService.hubConnection.stop();
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(() => {
      toggleButton.classList.add('toggled');
    }, 500);

    body.classList.add('nav-open');

    this.sidebarVisible = true;
  }

  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  }

  sidebarToggle() {
    const self = this;
    // const toggleButton = this.toggleButton;
    // const body = document.getElementsByTagName('body')[0];
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];

    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
    const body = document.getElementsByTagName('body')[0];

    if (this.mobile_menu_visible === 1) {
      // $('html').removeClass('nav-open');
      body.classList.remove('nav-open');
      if (this.layer) {
        this.layer.remove();
      }
      setTimeout(() => {
        $toggle.classList.remove('toggled');
      }, 400);

      this.mobile_menu_visible = 0;
    } else {
      setTimeout(() => {
        $toggle.classList.add('toggled');
      }, 430);

      this.layer = document.createElement('div');
      this.layer.setAttribute('class', 'close-layer');


      if (body.querySelectorAll('.main-panel')) {
        document.getElementsByClassName('main-panel')[0].appendChild(this.layer);
      } else if (body.classList.contains('off-canvas-sidebar')) {
        document.getElementsByClassName('wrapper-full-page')[0].appendChild(this.layer);
      }

      setTimeout(() => {
        this.layer.classList.add('visible');
      }, 100);

      this.layer.onclick = () => { // asign a function
        body.classList.remove('nav-open');
        self.mobile_menu_visible = 0;
        self.layer.classList.remove('visible');
        setTimeout(() => {
          self.layer.remove();
          $toggle.classList.remove('toggled');
        }, 400);
      };

      body.classList.add('nav-open');
      this.mobile_menu_visible = 1;

    }
  }

  public getTitle = () => {
    let title: string;
    title = this.location.prepareExternalUrl(this.location.path()).toString();
    if (title.charAt(0) === '#') {
      title = title.slice(1).toString();
    }
    // tslint:disable-next-line:prefer-for-of
    for (let item = 0; item < this.listTitles.length; item++) {
      if (title.includes(this.listTitles[item].path)) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }



  public openMenu = () => {
    this.menuFacade.setOpen(true);
  }
}
