<mat-label>{{ service.modelClass.langBase + label | translatePipe }}
    <span aria-hidden="true" class="mat-placeholder-required mat-form-field-required-marker ng-tns-c84-4 ng-star-inserted"> *</span>
</mat-label>

<div class="color-container">
    <input matInput [placeholder]="service.modelClass.langBase + label | translatePipe"
        [(ngModel)]="service.modelClass.model[name]" (change)="color()" type="color" name="{{ name }}"
        [disabled]="disabled" [required]="required">
    <p>{{service.modelClass.model[name]}}</p>
   

</div>