import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccountService } from '@services/cms/account.service';
import { Account } from '@models/account';
import {MyFile} from "@models/my-file";

@Component({
  selector: 'app-form-account',
  templateUrl: './form-account.component.html',
  styleUrls: ['./form-account.component.scss']
})
export class FormAccountComponent implements OnInit {
  public subtitle: string;
  private create: boolean;

  constructor(
      public dialogRef: MatDialogRef<FormAccountComponent>,
      public service: AccountService,
      @Inject(MAT_DIALOG_DATA) public data: Account
  ) {
    this.subtitle = this.service.modelClass.langBase + 'createEditSubtitle';
    this.service.modelClass.model = data;
    this.create = this.service.modelClass.model.accountID === 0;
  }

  ngOnInit(): void {
  }

  sendForm(): void {
    const self = this;
    // console.log(self.service.modelClass.model);
    // console.log('Create: ', this.create);

    if (this.create) {
      self.service.create().then((response) => {
        if (response) {
          self.close(true);
        }
      });
    } else {
      if (!self.service.modelClass.model['logo'].hasOwnProperty('data')) {
        self.service.modelClass.model['logo'] = {
          data: '',
          fileName: '',
          height: 0,
          timestamp: new Date(),
          uri: ''
        };
      }
      self.service.update(self.service.modelClass.model[self.service.modelClass.idName]).then((response) => {
        if (response) {
          self.close(true);
        }
      });
    }
  }

  public close = (reload: boolean = false) => {
    this.dialogRef.close({ data: { reload }});
  }
}
