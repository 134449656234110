export abstract class AbstractClass {
  // Abstract Properties
  public abstract modelName: string;
  public abstract formCells: FormCell[];
  public abstract formTabs: FormTabs[];
  public abstract formAccordion: FormAccordion[];
  public abstract tableCells: TableCell[];
  public abstract stringsToRemove: string[];
  public abstract langBase: string;
  public abstract idName: string;
  public abstract route: string;
  public abstract model: any;
  public jsonBase = {};

  constructor() {
  }

  public set = (model: any) => {
    const a = this.model;
    Object.entries(this.model).forEach(
        key => {
          switch (Object.prototype.toString.call(key[1])) {
            case '[object Date]':
              const tempDate = model[key[0]] !== null ? new Date(model[key[0]]) : new Date();
              const month = tempDate.getMonth() + 1;
              this.model[key[0]] = tempDate.getFullYear() + '-'
                  + (month < 10 ? '0' : '') + month + '-'
                  + (tempDate.getDate() < 10 ? '0' : '') + tempDate.getDate();
              break;
            case '[object Object]':
              if (key[0] === 'image') {
                this.model[key[0]] = null;
              } else {
                this.model[key[0]] = model[key[0]];
              }
              break;
            case '[object Array]':
              const arrayTemp = model[key[0]];
              arrayTemp.forEach((item: any) => {
                this.model[key[0]].push(item);
              });
              // this.model[key[0]] = model[key[0]];
              break;


            default:
              this.model[key[0]] = model[key[0]];
              break;
          }
        }
    );
  }

  public setAsync = (model: any) => {
    return new Promise(
        resolve => {
          Object.entries(this.model).forEach(
              key => {
                switch (Object.prototype.toString.call(key[1])) {
                  case '[object Date]':
                    const tempDate = model[key[0]] !== null ? new Date(model[key[0]]) : new Date();
                    const month = tempDate.getMonth() + 1;
                    this.model[key[0]] = tempDate.getFullYear() + '-'
                        + (month < 10 ? '0' : '') + month + '-'
                        + (tempDate.getDate() < 10 ? '0' : '') + tempDate.getDate();
                    break;
                  case '[object Object]':
                    if (key[0] === 'image') {
                      this.model[key[0]] = null;
                    } else {
                      this.model[key[0]] = model[key[0]];
                    }
                    break;
                  case '[object Array]':
                    const arrayTemp = model[key[0]];
                    arrayTemp.forEach((item: any) => {
                      this.model[key[0]].push(item);
                    });
                    // this.model[key[0]] = model[key[0]];
                    break;
                  default:
                    this.model[key[0]] = model[key[0]];
                    break;
                }
              }
          );
          resolve(true);
        }
    );
  }

  public getJsonBase = () => {
    const arrStrings = [this.idName, 'createdBy', 'createdAt', 'updatedAt'];
    this.stringsToRemove.map(s => {
      arrStrings.push(s);
    });
    this.jsonBase = {};
    Object.entries(this.model).forEach(
        key => {
          switch (Object.prototype.toString.call(key[1])) {
            default:
              if (arrStrings.indexOf(key[0]) === -1) {
                // @ts-ignore
                if (this.model[key[0]] !== null) {
                  // @ts-ignore
                  this.jsonBase[key[0]] = this.model[key[0]];
                }
              }
              break;
          }
        }
    );
    return this.jsonBase;
  }

  public getJsonUpdate = () => {
    const arrStrings = ['updatedAt'];
    this.stringsToRemove.map(s => {
      arrStrings.push(s);
    });
    this.jsonBase = {};
    Object.entries(this.model).forEach(
        key => {
          if (arrStrings.indexOf(key[0]) === -1) {
            switch (Object.prototype.toString.call(key[1])) {
              default:
                // @ts-ignore
                if (this.model[key[0]] !== null) {
                  // @ts-ignore
                  this.jsonBase[key[0]] = this.model[key[0]];
                }
                break;
            }
          }
        }
    );
    return this.jsonBase;
  }

  public getTablesCells = (): TableCell[] => this.tableCells;

  public getInputsTabForm = (): FormTabs[] => this.formTabs;

  public getInputsAccordionForm = (): FormAccordion[] => this.formAccordion;

  public getInputsForm = (): FormCell[] => this.formCells.sort((a, b) => a.order - b.order );

  public getDownloadTitles = (): TableCell[] => this.tableCells.filter(t => t.download);
}


export interface TableCell {
  name: string;
  label: string;
  cellType: string;
  order: boolean;
  download: boolean;
}

export interface FormCell {
  name: string;
  label: string;
  cellType: string;
  disable: boolean;
  cols: string;
  order: number;
  required: boolean;
  multiple: boolean;
}

export interface FormTabs {
  name: string;
  formCells: FormCell[];
}

export interface FormAccordion {
  name: string;
  formCells: FormCell[];
}
