import { Injectable } from '@angular/core';
import { AbstractClass } from './abstract.class';
import { Theme } from '@models/theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeClass extends AbstractClass{
  model: Theme;
  modelName = 'Themes';
  langBase = 'admin.components.theme.';
  idName = 'themeID';
  formCells = [];
  formTabs = [];
  formAccordion = [];
  tableCells = [
    { name: 'themeName', label: 'name', cellType: 'text', order: false, download: true },
    { name: 'isActive', label: 'isActive', cellType: 'active-text', order: false, download: true  }
  ];
  route = 'admin/theme';
  stringsToRemove = ['account'];

  constructor()
  {
    super();
    this.model = {
      themeID: 0,
      themeName: '',
      isActive: true
    };
  }
}
