import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { environment } from '../../../../environments/environment';
import { ChatMessage } from '@models/chat-message';
import { ChatMessageService } from '@services/cms/chat-message.service';
import { ChatMessageClass } from '@classes/chat-message.class';
import { NotificationFacade } from '@redux/notification.facade';
import { Live } from '@models/live';
import {Scene} from "@models/scene";
import {HubService} from "@services/hub/hub.service";
declare var configurations: any;

@Component({
  selector: 'app-card-chat-scene',
  templateUrl: './card-chat-scene.component.html',
  styleUrls: ['./card-chat-scene.component.scss']
})
export class CardChatSceneComponent implements OnInit, OnDestroy {
  @Input() public scene: Scene | null = null;
  private connection: any | null = null;
  public liveID = 0;
  public escenaBroadcastID = '';
  // public service: AbstractService;
  public messages: ChatMessage[] = [];
  public messageTxt = '';
  public replyMessageVar = false;

  constructor(
      public notificationFacade: NotificationFacade,
      private hubService: HubService,
      public service: ChatMessageService
  ) {
  }

  ngOnInit(): void {
    if (this.scene !== null) {
      console.log(this.scene);
      this.liveID = this.scene.liveID;
      this.escenaBroadcastID = this.scene.escenaBroadcastID;
      this.getAllMessages(this.scene.escenaID);
      this.hubService.joinToGroup(this.escenaBroadcastID);
      /* this.connection = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Information)
          .withUrl(configurations.url_env + 'livechat', {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
          })
          .build();

      this.connection.start().then(() => {
        console.log('SignalR Connected!');
        console.log(this.escenaBroadcastID);
        this.connection.invoke('AddToGroup', this.escenaBroadcastID);
      }).catch((err: any) => {
        this.notificationFacade.setError('Error conectandose al chat');
        return console.error(err.toString());
      });
      try {
        this.connection.on('ReceiveMessage', (data: ChatMessage) => {
          this.messages.push(data);
          setTimeout(() => {
            const myDiv = document.getElementById('chat-messages');
            if (myDiv !== null) {
              myDiv.scrollTop = myDiv.scrollHeight;
            }
          }, 50);
        });
        this.connection.onclose(async () => {
          console.log('close');
        });
      } catch (err) {
        this.notificationFacade.setError('Hubo un error enviando el mensaje');
      }*/
      this.hubService.signalReceiveMessage.subscribe((chat: ChatMessage) => {
        this.messages.push(chat);
        setTimeout(() => {
          const myDiv = document.getElementById('chat-messages');
          if (myDiv !== null) {
            myDiv.scrollTop = myDiv.scrollHeight;
          }
        }, 50);
      })
    }
  }

  ngOnDestroy(): void {
    console.log(this.connection);
    this.hubService.leaveToGroup(this.escenaBroadcastID);
    // this.connection.invoke('LeaveToGroup', this.escenaBroadcastID);
  }

  public getAllMessages = async (sceneID: number) => {
    this.service.getBySceneID(sceneID).then((data) => {
      if (data !== false) {
        this.messages.push(...this.service.data.data);
      }
    });
  }

  sendMessage = async () => {
    if (!this.replyMessageVar) {
      this.service.modelClass.model.reply = null;
      this.service.modelClass.model.replyToMessageID = null;
    }
    console.log(this.scene?.live?.topic?.name);
    this.service.modelClass.model.userMSG.name = this.scene?.live?.topic?.name;
    this.service.modelClass.model.userMSG.email = this.scene?.live?.topic?.email;
    this.service.modelClass.model.liveBroadcastID = this.scene?.live?.liveBroadcastID;
    this.service.modelClass.model.escenaBroadcastID = this.escenaBroadcastID;
    const msg = this.service.modelClass.model;
    try {
      console.log(msg);
      // await this.connection.invoke('SendMessageAsync', msg);
      await this.hubService.sendMessage(msg);
      this.service.modelClass = new ChatMessageClass();
      this.replyMessageVar = false;
    } catch (err) {
      this.notificationFacade.setError('Hubo un error enviando el mensaje');
    }
  }

  replyMessage = (message: ChatMessage) => {
    if (!message.isBrand) {
      this.replyMessageVar = true;
      this.service.modelClass.model.replyToMessageID = message.liveChatMessageID;
      this.service.modelClass.model.reply = {
        userMSG: {
          name: message.userMSG.name,
          email: message.userMSG.email,
        },
        txtMessage: message.txtMessage
      };
    }
  }

  deleteReplyMessage = () => {
    this.replyMessageVar = false;
    this.service.modelClass.model.reply = null;
  }

  blockUser = (user: any) => {
     this.service.blockUser(user , this.liveID).then((response) => {
      console.log(response);
    });
  }
}
