import { Component, OnInit } from '@angular/core';
import { AbstractService } from '@services/Abstract.service';

@Component({
  template: ''
})
// tslint:disable-next-line:component-class-suffix
export abstract class AbstractInputSelect {
  public abstract list: any[];
  public abstract selectTemporal: any[];
  public abstract reloadData: boolean;

  constructor(
      public service: AbstractService | null
  ) { }

  public loadData = (dataName: string | null) => {
    if (this.service !== null) {
      this.reloadData = true;
      this.service.getAll().then(
          (data) => {
            if (data === true) {
              this.reloadData = false;
              // console.log(this.service?.data);
              if (dataName !== null) {
                this.list = this.service?.data[dataName];
              } else {
                this.list = this.service?.data;
              }
            }
          }
      );
    }
  }

  public onKey = (e: any, key: string) => {
    const filter = e.target.value.toLowerCase();
    if (this.selectTemporal.length === 0) { this.selectTemporal = this.list; }
    this.list = this.selectTemporal.filter(i => i[key].toLowerCase().includes(filter) );
  }
}
