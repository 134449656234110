import { Injectable } from '@angular/core';
import { CustomHttpService } from '@services/generals/customHttp.service';
import { AbstractService } from '@services/Abstract.service';
import { LiveClass } from '@classes/live.class';

@Injectable({
    providedIn: 'root'
})
export class LiveService extends AbstractService {

    constructor(
        public customHttpService: CustomHttpService
    ) {
        super(customHttpService, new LiveClass());
    }

    public getMyEvents = () => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/GetMyEvents').then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }


    public getByTopicID = (topicId: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/GetByTopicId/' + topicId).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }
    public playLive = (id: any, liveOnAir: boolean = false): Promise<any> => {
        const self = this;
        const url = self.version + self.base + self.modelClass.modelName;
        const request = {
            liveID: id,
            liveOnAir
        };
        return new Promise(
            resolve => {
                self.customHttpService.httpPATCH(
                    false,
                    true,
                    url + '/StartLiveEvent',
                    request
                ).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(true);
                    }
                );
            }
        );
    }

    public stopLive = (id: any, liveOnAir: boolean = false): Promise<any> => {
        const self = this;
        const url = self.version + self.base + self.modelClass.modelName;
        const request = { liveOnAir };
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(
                    true,
                    true,
                    url + '/StopLiveEvent/' + id,
                    request
                ).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(true);
                    }
                );
            }
        );
    }
}
