import { Injectable } from '@angular/core';
import { AbstractClass } from './abstract.class';
import { Topic } from '@models/topic';

@Injectable({
  providedIn: 'root'
})
export class TopicClass extends AbstractClass{
  model: Topic;
  modelName = 'Topic';
  langBase = 'admin.components.topic.';
  idName = 'topicID';
  formCells = [
    // tslint:disable-next-line:max-line-length
    { name: 'accountID', label: 'account', cellType: 'accountID', disable: false, required: true, cols: '6', order: 1, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'name', label: 'name', cellType: 'text', disable: false, required: true, cols: '6', order: 2, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'email', label: 'email', cellType: 'text', disable: false, required: true, cols: '6', order: 2, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'adminMSGColor', label: 'adminMSGColor', cellType: 'color', disable: false, required: true, cols: '6', order: 2, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'adminBGColor', label: 'adminBGColor', cellType: 'color', disable: false, required: true, cols: '6', order: 2, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'isActive', label: 'isActive', cellType: 'active', disable: false, required: true, cols: '6', order: 2, multiple: false }

  ];
  formTabs = [];
  formAccordion = [];
  tableCells = [
    { name: 'account.name', label: 'account', cellType: 'text', order: false, download: true },
    { name: 'name', label: 'name', cellType: 'text', order: false, download: true },
    { name: 'liveBroadcastID', label: 'liveBroadcastID', cellType: 'text', order: false, download: true },
    { name: 'email', label: 'email', cellType: 'text', order: false, download: true },
    { name: 'adminMSGColor', label: 'adminMSGColor', cellType: 'text', order: false, download: true },
    { name: 'adminBGColor', label: 'adminBGColor', cellType: 'text', order: false, download: true },
    { name: 'countLives', label: 'countLives', cellType: 'number', order: false, download: true },
    { name: 'createdBy', label: 'createdBy', cellType: 'text', order: false, download: true },
    { name: 'isActive', label: 'isActive', cellType: 'active-text', order: false, download: true  }
  ];
  route = 'admin/topic';
  stringsToRemove = ['account'];

  constructor()
  {
    super();
    this.model = {
      topicID: 0,
      accountID: 0,
      name: '',
      email: '',
      adminBGColor: '#000000',
      adminMSGColor: '#000000',
      liveBroadcastID: '',
      countLives: 0,
      createdBy: '',
      createdAt: '',
      isActive: true
    };
  }
}
