import { Routes } from '@angular/router';
/** Pages */
import { HomeComponent } from './pages/home/home.component';
import { IndexLiveComponent } from './pages/live/index-live/index-live.component';
import { IndexOfferComponent } from './pages/offer/index-offer/index-offer.component';
import { IndexAccountComponent } from './pages/account/index-account/index-account.component';
import { ShowAccountComponent } from './pages/account/show-account/show-account.component';
import { IndexTopicComponent } from './pages/topic/index-topic/index-topic.component';
import {ShowTopicComponent} from "./pages/topic/show-topic/show-topic.component";
import {ShowLiveComponent} from "./pages/live/show-live/show-live.component";
import {ShowSceneComponent} from "./pages/scene/show-scene/show-scene.component";

export const AdminLayoutRoutes: Routes = [
  /* {
    path: 'users',
    component: UserHomeComponent,
    children: [
      { path: '', redirectTo: 'index', pathMatch: 'full' },
      { path: 'index', component: UserIndexComponent },
      { path: 'create', component: UserFormComponent },
      { path: 'edit/:id', component: UserFormComponent, pathMatch: 'full' }
    ]
  }, */
  { path: 'home', component: HomeComponent, pathMatch: 'full' },
  { path: 'offer', component: IndexOfferComponent, pathMatch: 'full' },
  { path: 'live', component: IndexLiveComponent, pathMatch: 'full' },
  { path: 'live/:id', component: ShowLiveComponent, pathMatch: 'full' },
  { path: 'account', component: IndexAccountComponent, pathMatch: 'full' },
  { path: 'account/:id', component: ShowAccountComponent, pathMatch: 'full' },
  { path: 'topic', component: IndexTopicComponent, pathMatch: 'full' },
  { path: 'topic/:id', component: ShowTopicComponent, pathMatch: 'full' },
  { path: 'scene/:id', component: ShowSceneComponent, pathMatch: 'full' },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];
