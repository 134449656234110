import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AbstractInputSelect } from '@components/field_form/select-inputs/abstract-input-select';
import { AbstractService } from '@services/Abstract.service';
import { CheilConnectService } from '@services/cheil-connect/cheil-connect.service';

@Component({
  selector: 'app-input-select-connect-cities',
  templateUrl: './input-select-connect-cities.component.html',
  styleUrls: ['./input-select-connect-cities.component.scss']
})
export class InputSelectConnectCitiesComponent extends AbstractInputSelect implements OnInit, AfterViewChecked {
  @Input() public serviceModel: AbstractService | null = null;
  @Input() public label = 'cities';
  @Input() public name = '';
  @Input() public required = false;
  @Input() public multiple = false;
  reloadData = true;
  list: any[] = [];
  selectTemporal: any[] = [];
  department: any = null;
  private isDataLoading = false;
  // Events
  @Output() changeAction = new EventEmitter<any>();
  @Output() loadAction = new EventEmitter<any>();

  constructor(
      public serviceCheilConnect: CheilConnectService
  ) {
    super(null);
  }

  ngOnInit(): void {
    this.loadData('data');
    this.reloadData = false;
  }

  ngAfterViewChecked(): void {
    if (this.serviceModel?.modelClass.model['department'] !== this.department) {
      Promise.resolve().then(() => {
        this.department = this.serviceModel?.modelClass.model['department'];
        console.log(this.department);
        this.loadData('data');
      });
    }
  }

  public loadData = (dataName: string | null) => {
    if (this.department !== null) {
      this.reloadData = true;
      this.serviceCheilConnect.getCitiesByDepartments(this.department).then(
          (data: any) => {
            if (data === true) {
              this.reloadData = false;
              if (dataName !== null) {
                this.list = this.serviceCheilConnect.data[dataName];
              } else {
                this.list = this.serviceCheilConnect.data;
              }
            }
          }
      );
    }
  }

  public change = () => {
    this.changeAction.emit();
  }
}
