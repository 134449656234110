<div *ngIf="scene !== null" class="admin-content-show-live">
    <div class="admin-content-header">
        <h1 class="admin-content-title">{{ title | translatePipe }}{{ ' ' + scene.nombreEscena }}</h1>
        <h2 class="admin-content-title-back" (click)="close()">Volver</h2>
    </div>
    <hr>
    <div class="admin-content-tab">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="details">
                <a class="nav-link active" id="details-tab" data-toggle="tab" href="#details" role="tab" aria-controls="details"
                   aria-selected="true">{{ 'admin.components.live.liveDetails.basicData' | translatePipe }}</a>
            </li>
            <li class="nav-item" role="chat">
                <a class="nav-link" id="chat-tab" data-toggle="tab" href="#chat" role="tab" aria-controls="chat"
                   aria-selected="false">{{ 'admin.components.live.liveDetails.chat' | translatePipe }}</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                <div class="basic-info">
                    <div class="row col-12">
                        <div class="row col-12 col-md-8">
                            <div class="col-12">
                                <strong>{{ service.modelClass.langBase + 'name' | translatePipe }}:</strong> {{ scene.nombreEscena }}
                            </div>
                            <div class="col-12">
                                <strong>{{ service.modelClass.langBase + 'subtitle' | translatePipe }}:</strong> {{ scene.subtituloEscena }}
                            </div>
                            <div class="col-12">
                                <strong>{{ service.modelClass.langBase + 'description' | translatePipe }}:</strong> {{ scene.descripcionEscena }}
                            </div>
                            <div class="col-12 basic-margin">
                                <strong>{{ service.modelClass.langBase + 'live' | translatePipe }}:</strong> {{ scene.live?.liveName }}
                            </div>
                            <div class="col-12">
                                <strong>{{ service.modelClass.langBase + 'sceneRTMP' | translatePipe }}:</strong> {{ scene.escenaRTMP }}
                            </div>
                            <div class="col-12 basic-margin">
                                <strong>{{ service.modelClass.langBase + 'sceneFrontVideoURL' | translatePipe }}:</strong> {{ scene.escenaFrontVideoURL }}
                            </div>
                            <div class="col-12">
                                <strong>{{ service.modelClass.langBase + 'sceneFrontVideoURLIOS' | translatePipe }}:</strong> {{ scene.escenaFrontVideoURLIPhone }}
                            </div>
                            <div class="col-12 basic-margin">
                                <strong>{{ service.modelClass.langBase + 'state' | translatePipe }}:</strong> {{ service.modelClass.langBase + (scene.isActive ? 'active' : 'inactive') | translatePipe }}
                            </div>
                            <div class="row col-12">
                                <div class="col-6">
                                    <strong>{{ service.modelClass.langBase + 'clicks' | translatePipe }}:</strong> {{ scene.clicsEscena }}
                                </div>
                            </div>
                            <div class="row col-12" *ngIf="isAdmin()">
                                <div class="col-12 col-sm-6 col-md-3">
                                    <button class="btn-icon" *ngIf="!scene.isActive" (click)="setAlert()">
                                        <p>{{ 'admin.components.scene.activate' | translatePipe }}</p>
                                        <span class="material-icons">play_arrow</span>
                                    </button>
                                    <button class="btn-icon-test" *ngIf="scene.isActive" (click)="setAlert()">
                                        <p>{{ 'admin.components.scene.deactivate' | translatePipe }}</p>
                                        <span class="material-icons">stop</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 show-min">
                            <img class="img-scene" src="{{ scene.imagenEscena }}" alt="{{ 'image' }}">
                            <div class="titleDivScene">
                                <h1>{{ scene.nombreEscena }}</h1>
                                <h2>{{ scene.subtituloEscena }}</h2>
                                <p>{{ scene.descripcionEscena }}</p>
                            </div>
                        </div>
                    </div>
                    <h1 class="title">Video</h1>
                    <div class="row col-12">
                        <ng-amp
                                *ngIf="sceneVideoUrl !== ''"
                                src="{{ sceneVideoUrl }}"
                                class="video-preview"
                                (load)="close()"
                                [controls]="true"
                                [autoplay]="true"
                                [fluid]="true"
                                [poster]="scene.imagenEscena">
                        </ng-amp>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="chat" role="tabpanel" aria-labelledby="chat-tab">
                <app-card-chat-scene [scene]="scene" *ngIf="scene !== null"></app-card-chat-scene>
            </div>
        </div>
    </div>
    <app-alert
            *ngIf="showAlert"
            [title]="titleAlert"
            [type]="'action'"
            [description]="descriptionAlert"
            [success]="'warning'"
            [primaryButton]="'ok'"
            [secondaryButton]="'cancel'"
            (primaryAction)="activateOrDeactivateScene()"
    ></app-alert>
</div>
