import { Injectable } from '@angular/core';
import { AbstractClass } from './abstract.class';
import { Hint } from '@models/hint';

@Injectable({
    providedIn: 'root'
})
export class HintClass extends AbstractClass{
    model: Hint;
    modelName = 'Hints';
    langBase = 'admin.components.hint.';
    idName = 'hintID';
    formCells = [
        // tslint:disable-next-line:max-line-length
        { name: 'liveID', label: 'live', cellType: 'liveID', disable: false, required: true, cols: '6', order: 2, multiple: false },
        // tslint:disable-next-line:max-line-length
        { name: 'hintType', label: 'hintType', cellType: 'hintType', disable: false, required: true, cols: '12', order: 3, multiple: false },
        // tslint:disable-next-line:max-line-length
        { name: 'hintTxt', label: 'hintTxt', cellType: 'textarea', disable: false, required: true, cols: '12', order: 3, multiple: false },
    ];
    formTabs = [];
    formAccordion = [];
    tableCells = [
      { name: 'live', label: 'live', cellType: 'text', order: false, download: true },
      { name: 'hintTxt', label: 'hintTxt', cellType: 'text', order: false, download: true },
      { name: 'hintType', label: 'hintType', cellType: 'text', order: false, download: true },
      { name: 'contentID', label: 'content', cellType: 'text', order: false, download: true },
      { name: 'clicsHint', label: 'clicks', cellType: 'text', order: false, download: true },
      { name: 'createdAt', label: 'createdAt', cellType: 'date', order: false, download: true }
    ];
    /* tableCells = [
      { name: 'email', label: 'live', cellType: 'text', order: false, download: true },
      { name: 'name', label: 'name', cellType: 'text', order: false, download: true },
      { name: 'last_name', label: 'last_name', cellType: 'text', order: false, download: true },
      { name: 'city', label: 'city', cellType: 'text', order: false, download: true },
      { name: 'mobile_phone', label: 'mobile_phone', cellType: 'text', order: false, download: true },
      { name: 'celular-actual', label: 'celular-actual', cellType: 'text', order: false, download: true },
      { name: 'unpack-galaxy-reportero-2022-August-passionate', label: 'apasiona', cellType: 'text', order: false, download: true },
      { name: 'unpack-galaxy-reportero-2022-August-offer-fav', label: 'Favo', cellType: 'text', order: false, download: true },
      { name: 'unpack-galaxy-reportero-2022-August-code', label: 'code', cellType: 'text', order: false, download: true },
      { name: 'unpack-galaxy-reportero-2022-August-category', label: 'cate', cellType: 'text', order: false, download: true },
      { name: 'created_at', label: 'creado', cellType: 'date', order: false, download: true },
      { name: 'updated_at', label: 'actualizado', cellType: 'date', order: false, download: true },
    ]; */
    /* tableCells = [
        { name: 'full_name', label: 'full_name', cellType: 'text', order: false, download: true },
        { name: 'employee_number', label: 'employee_number', cellType: 'text', order: false, download: true },
        { name: 'date', label: 'date', cellType: 'date', order: false, download: true },
        { name: 'creationDate', label: 'creationDate', cellType: 'datetime', order: false, download: true },
        { name: 'serial', label: 'serial', cellType: 'text', order: false, download: true },
        { name: 'work_area', label: 'work_area', cellType: 'text', order: false, download: true },
        { name: 'q1', label: 'q1', cellType: 'text', order: false, download: true },
        { name: 'q2', label: 'q2', cellType: 'text', order: false, download: true },
        { name: 'q3', label: 'q3', cellType: 'text', order: false, download: true },
        { name: 'q4', label: 'q4', cellType: 'text', order: false, download: true },
        { name: 'q5', label: 'q5', cellType: 'text', order: false, download: true },
        { name: 'q6', label: 'q6', cellType: 'text', order: false, download: true },
        { name: 'q7', label: 'q7', cellType: 'text', order: false, download: true },
        { name: 'q8', label: 'q8', cellType: 'text', order: false, download: true },
        { name: 'q9', label: 'q9', cellType: 'text', order: false, download: true },
        { name: 'q10', label: 'q10', cellType: 'text', order: false, download: true },
        { name: 'qr', label: 'qr', cellType: 'url', order: false, download: true },
        { name: 'signature', label: 'signature', cellType: 'url', order: false, download: true }
    ]; */
    /* tableCells = [
        { name: 'email', label: 'live', cellType: 'text', order: false, download: true },
        { name: 'name', label: 'name', cellType: 'text', order: false, download: true },
        { name: 'last_name', label: 'last_name', cellType: 'text', order: false, download: true },
        { name: 'mobile_phone', label: 'mobile_phone', cellType: 'text', order: false, download: true },
        { name: 'samsung-unpack-2023-01-smartphone', label: 'smartphone', cellType: 'text', order: false, download: true },
        { name: 'samsung-unpack-2023-01-interest', label: 'Favo', cellType: 'text', order: false, download: true },
        { name: 'samsung-unpack-2023-01-offer', label: 'offer', cellType: 'text', order: false, download: true },
        { name: 'samsung-unpack-2023-01-code', label: 'code', cellType: 'text', order: false, download: true },
        { name: 'created_at', label: 'creado', cellType: 'date', order: false, download: true },
        { name: 'updated_at', label: 'actualizado', cellType: 'date', order: false, download: true },
    ]; */

    route = 'admin/poll';
    stringsToRemove = [''];

    constructor()
    {
        super();
        this.model = {
            hintID: 0,
            liveID: 0,
            clicsHint: 0,
            hintTxt: '',
            hintType: '',
            contentID: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
            createdBy: ''
        };
    }
}
