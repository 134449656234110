<nav class="navbar navbar-expand-lg navbar-transparent  navbar-absolute fixed-top">

    <a class="navbar-brand" href="#">
      <img src="{{ images.imageCheil }}" width="" height="60" alt="">
    </a>

  <div class="container-fluid">
    <div class="icon-menu" (click)="openMenu()">
      <mat-icon class="material-icons">menu</mat-icon>
    </div>
    <!--
    <div class="navbar-wrapper">
      <app-select-language></app-select-language>
    </div> -->

  </div>
</nav>
