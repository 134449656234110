import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import * as thisActions from '../actions/notification.actions';
import * as thisReducer from '../reducers/notification.reducer';
import * as thisSelectors from '../selectors/notification.selectors';
import { Notification } from '@models/notification';

@Injectable()
export class NotificationFacade {

  notificationState$: Observable<Notification> = this.store.pipe(
    select(thisSelectors.selectNotificationState)
  );

  constructor(
    private store: Store<thisReducer.AuthPartialState>
  ) {}

  private dispatch = (action: Action) => {
    this.store.dispatch(action);
  }

  public setNotification = (message: string) => {
    this.dispatch(thisActions.setNotification({ message }));
  }

  public setSuccess = (message: string) => {
    this.dispatch(thisActions.setSuccess({ message }));
  }

  public setError = (message: string) => {
    this.dispatch(thisActions.setError({ message }));
  }

  public closeNotification = () => {
    this.dispatch(thisActions.closeNotification());
  }
}
