import { Injectable } from '@angular/core';
import { CustomHttpService } from '@services/generals/customHttp.service';
import { AbstractService } from '@services/Abstract.service';
import { ScenesClass } from '@classes/scenes.class';

@Injectable({
    providedIn: 'root'
})
export class SceneService extends AbstractService {

    constructor(
        public customHttpService: CustomHttpService
    ) {
        super(customHttpService, new ScenesClass());
    }

    public getEscenasByLiveID = (liveId: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/GetEscenasByLiveID/' + liveId).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }

    public playScene = (id: any, liveOnAir: boolean = false): Promise<any> => {
        const self = this;
        const url = self.version + self.base + self.modelClass.modelName;
        const request = { liveOnAir };
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(
                    true,
                    true,
                    url + '/StartLive/' + id,
                    request
                ).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(true);
                    }
                );
            }
        );
    }

    public stopScene = (id: any, liveOnAir: boolean = false): Promise<any> => {
        const self = this;
        const url = self.version + self.base + self.modelClass.modelName;
        const request = { liveOnAir };
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(
                    true,
                    true,
                    url + '/StopLive/' + id,
                    request
                ).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(true);
                    }
                );
            }
        );
    }

    public async activateOrDeactivateScene(id: number): Promise<any> {
        const self = this;
        const url = self.version + self.base;
        return await new Promise(
            resolve => {
                self.customHttpService.httpPATCH(
                    true,
                    true,
                    url + this.modelClass.modelName + '/ActivarDesactivarEscena/' + id,
                    this.modelClass.getJsonUpdate()
                ).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(true);
                    }
                );
            }
        );
    }
}
