import { Component, Input , OnInit } from '@angular/core';
import { Topic } from '@models/topic';
import { AbstractService } from '@services/Abstract.service';
import { LiveService } from '@services/cms/live.service';


@Component({
  selector: 'app-input-picker-color-live',
  templateUrl: './input-picker-color-live.component.html',
  styleUrls: ['./input-picker-color-live.component.css']
})
export class InputPickerColorLiveComponent implements OnInit {

  @Input() public serviceModel: AbstractService | null = null;
  @Input() public label = '';
  @Input() public name = '';
  @Input() public required = false;
  @Input() public multiple = false;
  @Input() public disabled = false;

  constructor(
    public service:LiveService
  ) { }

  ngOnInit(): void {
  }

  color(){   
    console.log(this.service.modelClass.model); 
  }

}
