import { Component, OnInit } from '@angular/core';
import { AccountService } from '@services/cms/account.service';
import { Account } from '@models/account';
import { ActivatedRoute } from '@angular/router';
declare var configurations: any;

@Component({
  selector: 'app-show-account',
  templateUrl: './show-account.component.html',
  styleUrls: ['./show-account.component.scss']
})
export class ShowAccountComponent implements OnInit {
  private id: any | null;
  public title: string;
  public account: Account | null = null;
  public totalLives: string = '';
  public totalGroups: string = '';
  public url_env = configurations.url_env;

  constructor(
      public service: AccountService,
      private activatedRoute: ActivatedRoute,
  ) {
    this.title = this.service.modelClass.langBase + 'singularTitle';
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    // console.log(this.id);
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {
    this.account = null;
    this.service.getByID(this.id).then((result) => {
      if (result) {
        // console.log(this.service.data);
        this.account = this.service.data.data;
        this.totalLives = this.service.data.stats.totalLives;
        this.totalGroups = this.service.data.stats.totalGroups;
      }
    });
  }

  public close = () => {
    history.back();
  }
}
