<div class="stats-component">
    <div class="stats-header">
        <!-- div class="stats-filter">
            <input type="number" [id]="minutes" />
            <app-button type=""></app-button>
        </div -->
    </div>
    <div class="stats-content">
        <div class="stats-last-update row">
            <div class="col-6 col-md-8 col-lg-10">
                <p class="date-last-update">{{ 'commons.lists.lastUpdate' | translatePipe }}: {{ dateReport }}</p>
            </div>
            <div class="col-6 col-md-4 col-lg-2">
                <button class="btn-icon" (click)="loadData()">
                    <p>{{ 'commons.lists.reload' | translatePipe }} </p>
                    <span class="material-icons">loop</span>
                </button>
            </div>
        </div>
        <div class="stats-general">
            <h1 class="title">Estadisticas Generales</h1>
            <div class="row" *ngIf="liveStatsResponse !== null">
                <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <app-card-stats-number [title]="'Total Likes'" [value]="liveStatsResponse.liveLikes + ''">
                    </app-card-stats-number>
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <app-card-stats-number [title]="'Total Compartido'" [value]="liveStatsResponse.liveShares + ''">
                    </app-card-stats-number>
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <app-card-stats-number [title]="'Total Clics'" [value]="liveStatsResponse.liveClics + ''">
                    </app-card-stats-number>
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <app-card-stats-number [title]="'Interacciones'" [value]="liveStatsResponse.totalInteractions">
                    </app-card-stats-number>
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <app-card-stats-number [title]="'Interacciones / Usuarios'" [value]="interactions">
                    </app-card-stats-number>
                </div>

                <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <app-card-stats-number [title]="'Tiempo total Live'" [value]="liveStatsResponse.totalTime">
                    </app-card-stats-number>
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <app-card-stats-number [title]="'Total Ofertas'" [value]="totalOffers">
                    </app-card-stats-number>
                </div>




            </div>
        </div>
        <div class="stats-users">
            <h1 class="title">Estadisticas de usuarios</h1>
            <div *ngIf="usersPerTime.length > 0">

                <div class="row">
                    <div class="col-12 col-lg-6" style="height: 400px">
                        <ngx-charts-line-chart [legendTitle]="'Usuarios por minuto'" [legend]="false"
                            [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxis]="true" [yAxis]="true"
                            [animations]="true" [xAxisLabel]="'Tiempo'" [yAxisLabel]="'Usuarios conectados'"
                            [timeline]="true" [results]="usersPerTime">
                        </ngx-charts-line-chart>
                    </div>
                    <div class="col-12 col-lg-6" style="height: 400px">
                        <ngx-charts-line-chart [legendTitle]="'Usuarios por minuto'" [legend]="false"
                            [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxis]="true" [yAxis]="true"
                            [animations]="true" [xAxisLabel]="'Tiempo'" [yAxisLabel]="'Usuarios totales por tiempo'"
                            [timeline]="true" [results]="usersPerTimeSum">
                        </ngx-charts-line-chart>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-12">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <app-card-stats-number [title]="'Total conexiones'"
                                    [value]="userResponse.totalConnections"></app-card-stats-number>
                            </div>
                            <div class="col-12 col-md-4">
                                <app-card-stats-number [title]="'Total desconexiones'"
                                    [value]="userResponse.totalDisconnections"></app-card-stats-number>
                            </div>
                            <div class="col-12 col-md-4">
                                <app-card-stats-number [title]="'Max conexiones'"
                                    [value]="userResponse.totalMaxConnections"></app-card-stats-number>
                            </div>
                            <div class="col-12 col-md-4">
                                <app-card-stats-number [title]="'Promedio conexión'" [value]="averageTime">
                                </app-card-stats-number>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div class="stats-messages">
            <h1 class="title">Estadisticas de mensajes</h1>
            <div class="row" *ngIf="messagesPerTime.length > 0">
                <div class="col-12 col-lg-6" style="height: 400px">
                    <ngx-charts-line-chart [legendTitle]="'Messages por minuto'" [legend]="false"
                        [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxis]="true" [yAxis]="true"
                        [animations]="true" [xAxisLabel]="'Tiempo'" [yAxisLabel]="'Mensajes enviados'" [timeline]="true"
                        [results]="messagesPerTime">
                    </ngx-charts-line-chart>
                </div>
                <div class="col-12 col-lg-6" style="height: 400px" *ngIf="messagesPerTimeSum.length > 0">
                    <ngx-charts-line-chart [legendTitle]="'Messages por minuto'" [legend]="false"
                        [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxis]="true" [yAxis]="true"
                        [animations]="true" [xAxisLabel]="'Tiempo'" [yAxisLabel]="'Mensajes enviados'" [timeline]="true"
                        [results]="messagesPerTimeSum">
                    </ngx-charts-line-chart>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <app-card-stats-number [title]="'Total Mensajes'" [value]="messagesResponse.totalMessages">
                            </app-card-stats-number>
                        </div>
                        <div class="col-12 col-md-6">
                            <app-card-stats-number [title]="'Total Respuestas'" [value]="messagesResponse.totalReplys">
                            </app-card-stats-number>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="stats-messages">
            <h1 class="title">Estadisticas de Ofertas</h1>
            <div class="row" *ngIf="clicksByOffert.length > 0">
                <div class="col-12 col-lg-12" style="height: 400px; width: 90%">
                    <ngx-charts-bar-vertical [results]="clicksByOffert" [xAxisLabel]="'Ofertas'"
                        [legendTitle]="'Product Sale Chart'" [yAxisLabel]="'Clicks'" [legend]="true"
                        [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxis]="true" [yAxis]="true" [gradient]="true">
                    </ngx-charts-bar-vertical>
                </div>
            </div>
        </div>
    </div>
</div>
