import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { select, Store, Action } from '@ngrx/store';
import * as thisActions from '../actions/menu.actions';
import * as thisReducer from '../reducers/menu.reducer';
import * as thisSelectors from '../selectors/menu.selectors';
import { Menu } from '../reducers/menu.reducer';

@Injectable()
export class MenuFacade {

  menuState$: Observable<Menu> = this.store.pipe(
    select(thisSelectors.selectMenuState)
  );

  constructor(
    private store: Store<thisReducer.MenuPartialState>
  ) {}

  private dispatch = (action: Action) => {
    this.store.dispatch(action);
  }

  public changeState = () => {
    this.dispatch(thisActions.changeState());
  }

  public setOpen = (open: boolean) => {
    this.dispatch(thisActions.setOpen({ open }));
  }

  public setPermanent = (permanent: boolean) => {
    this.dispatch(thisActions.setPermanent({ permanent }));
  }
}
