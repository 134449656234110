import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractInputSelect } from '@components/field_form/select-inputs/abstract-input-select';
import { AbstractService } from '@services/Abstract.service';
import { CheilConnectService } from '@services/cheil-connect/cheil-connect.service';

@Component({
  selector: 'app-input-select-connect-departments',
  templateUrl: './input-select-connect-departments.component.html',
  styleUrls: ['./input-select-connect-departments.component.scss']
})
export class InputSelectConnectDepartmentsComponent extends AbstractInputSelect implements OnInit {
  @Input() public serviceModel: AbstractService | null = null;
  @Input() public label = 'department';
  @Input() public name = '';
  @Input() public required = false;
  @Input() public multiple = false;
  reloadData = true;
  list: any[] = [];
  selectTemporal: any[] = [];
  private isDataLoading = false;
  // Events
  @Output() changeAction = new EventEmitter<any>();
  @Output() loadAction = new EventEmitter<any>();

  constructor(
      public serviceCheilConnect: CheilConnectService
  ) {
    super(null);
  }

  ngOnInit(): void {
    this.loadData('data');
  }

  public loadData = (dataName: string | null) => {
    this.reloadData = true;
    this.serviceCheilConnect.getDepartments().then(
        (data: any) => {
          if (data === true) {
            this.reloadData = false;
            if (dataName !== null) {
              this.list = this.serviceCheilConnect.data[dataName];
            } else {
              this.list = this.serviceCheilConnect.data;
            }
          }
        }
    );
  }

  public change = () => {
    this.changeAction.emit();
  }
}
