import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AbstractInputSelect } from '@components/field_form/select-inputs/abstract-input-select';
import { AbstractService } from '@services/Abstract.service';
import { CheilConnectService } from '@services/cheil-connect/cheil-connect.service';

@Component({
  selector: 'app-input-select-connect-type-id',
  templateUrl: './input-select-connect-type-id.component.html',
  styleUrls: ['./input-select-connect-type-id.component.scss']
})
export class InputSelectConnectTypeIdComponent extends AbstractInputSelect implements OnInit {
  @Input() public serviceModel: AbstractService | null = null;
  @Input() public label = 'type_id';
  @Input() public name = '';
  @Input() public required = false;
  @Input() public multiple = false;
  reloadData = true;
  list: any[] = [];
  selectTemporal: any[] = [];
  department: any = null;
  private isDataLoading = false;
  // Events
  @Output() changeAction = new EventEmitter<any>();
  @Output() loadAction = new EventEmitter<any>();

  constructor() {
    super(null);
  }

  ngOnInit(): void {
    this.loadData('data');
  }

  public loadData = (dataName: string | null) => {
    this.reloadData = true;
    Promise.resolve().then(() => {
      this.list = [
        { name: 'Cédula de ciudadania', id: 'CC'},
        { name: 'Cédula de extranjeria', id: 'CE'},
        { name: 'Pasaporte', id: 'PP'}
      ];
      this.reloadData = false;
    });
  }

  public change = () => {
    this.changeAction.emit();
  }
}
