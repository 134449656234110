<div *ngIf="account !== null">
    <div class="admin-content-header">
        <h1 class="admin-content-title">{{ title | translatePipe }}{{ ' ' + account.name }}</h1>
        <h2 class="admin-content-title-back" (click)="close()">Volver</h2>
    </div>
    <hr>
    <div class="admin-content-tab">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#basic" role="tab" aria-controls="home"
                    aria-selected="true">{{ service.modelClass.langBase + 'basicData' | translatePipe }}</a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="offers-tab" data-toggle="tab" href="#offers" role="tab" aria-controls="profile"
                    aria-selected="false">{{ service.modelClass.langBase + 'groups' | translatePipe }}</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="basic" role="tabpanel" aria-labelledby="home-tab">
                <div class="basic-info">
                    <div class="row col-12">
                        <div class="col-12 basic-margin">
                            <strong>{{ service.modelClass.langBase + 'logo' | translatePipe }}:</strong><img id="logo"
                                src="{{ url_env + '' }}Resources/Accounts/SamsungAccount.jpg" alt="">
                        </div>
                        <div class="col-12 basic-margin">
                            <strong>{{ service.modelClass.langBase + 'name' | translatePipe }}:</strong> {{ account.name
                            }}
                        </div>
                        <div class="row col-12">
                            <div class="col-8 basic-margin">
                                <strong>{{ service.modelClass.langBase + 'description' | translatePipe }}:</strong> {{
                                account.description }}
                            </div>
                            <div class="col-4 basic-margin">
                                <strong>{{ service.modelClass.langBase + 'email' | translatePipe }}:</strong> {{
                                account.email }}
                            </div>
                        </div>
                        <div class="col-12 basic-margin">
                            <strong>{{ service.modelClass.langBase + 'isActive' | translatePipe }}:</strong>{{
                            account.isActive }}
                        </div>
                        <div class="col-12 basic-margin">
                            <strong>{{ service.modelClass.langBase + 'Creador' | translatePipe }}:</strong>{{
                            account.createdBy }}
                        </div>
                        <div class="col-12 basic-margin">
                            <strong>{{ service.modelClass.langBase + 'createdAt' | translatePipe }}:</strong>{{
                            account.createdAt }}
                        </div>
                    </div>
                    <div class="row" id="stats">

                            <h1 class="title">Estadisticas Generales</h1>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                <app-card-stats-number [title]="'Total lives'" [value]="totalLives"></app-card-stats-number>
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                                <app-card-stats-number [title]="'Total Grupos'" [value]="totalGroups"></app-card-stats-number>
                            </div>




                    </div>







                </div>
            </div>
            <div class="tab-pane fade" id="offers" role="tabpanel" aria-labelledby="offers-tab">
                <app-index-topic [accountId]="account.accountID"></app-index-topic>
            </div>
        </div>
    </div>
</div>
