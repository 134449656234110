import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Images } from '@constants/images';
import { PollQuestion } from '@models/poll-question';
import { PollQuestionType } from '@models/poll-question-type';
import { PollQuestionChoice } from '@models/poll-question-choice';
import { PollService } from '@services/cms/poll.service';
import { NotificationFacade } from '@redux/notification.facade';

@Component({
  selector: 'app-review-question-form',
  templateUrl: './review-question-form.component.html',
  styleUrls: ['./review-question-form.component.scss']
})
export class ReviewQuestionFormComponent implements OnInit, AfterViewChecked {
  @Input() service: PollService | null = null;
  public imageClose = Images.iconClosePopup;
  @ViewChild('showQuestion') showQuestion: ElementRef | null = null;
  public showFormQuestion: boolean = false;
  public createBtnQuestion = 'admin.components.poll.createBtnQuestion';
  public questions: PollQuestion[] = []
  public questionSelected: PollQuestion | null = null;
  public questionTypes: PollQuestionType[] = [
    { pollQuestionTypeID: 1, type: 'choice', isActive: true, createdAt: new Date(), updatedAt: new Date(), createdBy: '' },
    { pollQuestionTypeID: 2, type: 'multiple-choice', isActive: true, createdAt: new Date(), updatedAt: new Date(), createdBy: '' }
  ];
  public createQuestion: PollQuestion | null = null;
  public showFormChoices: boolean = true;
  private questionEdit: number | null = null;
  public showErrorNotCorrectChoice = false;

  constructor(
    private modal: NgbModal,
    public images: Images,
    public notificationFacade: NotificationFacade,
  ) {
    console.log(this.service?.modelClass);
    this.questions = [];
    this.setDefaultQuestion();
  }

  ngOnInit(): void {
    console.log(this.service?.modelClass.model);
    /* this.questionTypeService.getAll().then((data) => {
      if (data) {
        this.questionTypes = this.questionTypeService.data.data;
        console.log([this.questionTypes, this.questionTypeService.data]);
      }
    }); */
  }

  ngAfterViewChecked() {
    if (this.service !== null) {
      if (this.questions !== this.service.modelClass.model['pollQuestions']) {
        this.questions = this.service.modelClass.model['pollQuestions'];
        console.log(this.questions);
      }
    }
  }

  public setDefaultQuestion = () => this.createQuestion = {
    pollQuestionID: 0,
    pollID: 0,
    pollQuestionTypeID: 0,
    txtQuestion: '',
    reviewAnswerType: '',
    isActive: true,
    createdAt: new Date(),
    updatedAt: new Date(),
    createdBy: '',
    pollQuestionChoices: [],
    pollQuestionType: null,
    poll: null
  };

  public setQuestionsToModel = () => {
    if (this.service !== null) {
      // @ts-ignore
      this.service.modelClass.model['pollQuestions'] = this.questions;
    }
  }

  openModal(content: any) {
    this.modal.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result
      .then((result) => { },
        (reason) => { });
  }

  public openQuestion = (q: PollQuestion) => {
    this.questionSelected = q;
    this.openModal(this.showQuestion);
  }

  public openFormQuestion = () => {
    this.questionEdit = null;
    this.setDefaultQuestion();
    console.log(this.createQuestion);
    this.showFormQuestion = true;
  }

  public openFormUpdate = (question: PollQuestion) => {
    this.questionEdit = this.questions.indexOf(question);
    this.createQuestion = question;
    this.showFormQuestion = true;
  }

  // Choices
  public addChoiceToQuestion = () => {
    if (this.createQuestion !== null) {
      this.showFormChoices = false;
      this.createQuestion.pollQuestionChoices.push({
        pollQuestionChoiceID: this.createQuestion.pollQuestionChoices.length,
        pollQuestionID: 0,
        txtChoise: '',
        isCorrectChoise: false,
        order: 1,
        counter: 0,
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: '',
        pollQuestion: null,
      });
      this.showFormChoices = true;
    }
  }
  public selectCorrectChoice = (choice: PollQuestionChoice) => {
    this.createQuestion?.pollQuestionChoices.map((item) => {
      if (this.createQuestion?.pollQuestionTypeID === 1 && choice.isCorrectChoise) {
        item.isCorrectChoise = item.pollQuestionChoiceID === choice.pollQuestionChoiceID;
      }
    });
  }

  public deleteChoice = (choice: PollQuestionChoice) => {
    if (this.createQuestion !== null)
      this.createQuestion.pollQuestionChoices = this.createQuestion.pollQuestionChoices.filter(x => x !== choice);
  }
  // End Choices
  public addQuestion = () => {
    if (this.createQuestion !== null) {
      this.createQuestion.pollQuestionType = this.questionTypes.filter(x => x.pollQuestionTypeID == this.createQuestion?.pollQuestionTypeID)[0];
      if (this.service !== null) {
        if (this.questionEdit !== null) {
          this.questions[this.questionEdit] = this.createQuestion;
        } else {
          // @ts-ignore
          this.service?.modelClass.model['pollQuestions'].push(this.createQuestion);
        }
      }
      this.showFormQuestion = false;
      this.setQuestionsToModel();
    }
  }

  public deleteQuestion = (question: PollQuestion) => {
    console.log('Que recibo: ', question);

    // Validamos si la pregunta existe en la base de datos
    this.service?.GetIfExist(question.pollQuestionID).then(
      (data) => {
        if (data !== null) {
          console.log('este es data: ', data);

          // Pregunta no existe en DB
          if(data === false) {
            this.questions = this.questions.filter(x => x !== question);
            this.setQuestionsToModel();
          }
          // Pregunta existe en DB
          else {

            this.service?.deleteQuestion(question.pollQuestionID).then((data) => {
              if (data) {

                console.log('Datos eliminiacion: ', data);

                if (data === true) {

                  this.questions = this.questions.filter(x => x !== question);
                  this.setQuestionsToModel();

                  this.notificationFacade?.setSuccess('cms.components.review.removeSuccessful');
                } else {
                  this.notificationFacade?.setError('no se puede borrar (Ajustar diccionario)');
                }
              }
            });
          }
        }
      }
    );
  }

}
