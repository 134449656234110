import { Injectable } from '@angular/core';
import { AbstractClass } from './abstract.class';
import { Topic } from '@models/topic';
import {Poll} from "@models/poll";
import {Live} from "@models/live";
import {Scene} from "@models/scene";

@Injectable({
  providedIn: 'root'
})
export class PollClass extends AbstractClass{
  model: Poll;
  modelName = 'Polls';
  langBase = 'admin.components.poll.';
  idName = 'pollID';
  formCells = [
    // tslint:disable-next-line:max-line-length
    { name: 'liveID', label: 'live', cellType: 'liveID', disable: false, required: true, cols: '6', order: 1, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'escenaID', label: 'scene', cellType: 'sceneID', disable: false, required: false, cols: '6', order: 2, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'title', label: 'title', cellType: 'text', disable: false, required: true, cols: '6', order: 3, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'subtitle', label: 'subtitle', cellType: 'text', disable: false, required: true, cols: '6', order: 4, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'description', label: 'description', cellType: 'textarea', disable: false, required: true, cols: '6', order: 5, multiple: false },
    // tslint:disable-next-line:max-line-length
    { name: 'isActive', label: 'isActive', cellType: 'active', disable: false, required: true, cols: '6', order: 6, multiple: false }

  ];
  formTabs = [];
  formAccordion = [];
  tableCells = [
    { name: 'live.liveName', label: 'live', cellType: 'text', order: false, download: true },
    { name: 'escena.nombreEscena', label: 'scene', cellType: 'text', order: false, download: true },
    { name: 'title', label: 'pollTitle', cellType: 'text', order: false, download: true },
    { name: 'subtitle', label: 'subtitle', cellType: 'text', order: false, download: true },
    { name: 'isActive', label: 'isActive', cellType: 'active-text', order: false, download: true  }
  ];
  tableCellsRTAS = [
    { name: 'pregunta', label: 'pregunta', cellType: 'text', order: false, download: true },
    { name: 'respuesta', label: 'respuesta', cellType: 'text', order: false, download: true },
    { name: 'correcta', label: 'correcta', cellType: 'text', order: false, download: true },
    { name: 'userName', label: 'userName', cellType: 'text', order: false, download: true },
    { name: 'email', label: 'email', cellType: 'text', order: false, download: true },
    { name: 'fecha', label: 'fecha', cellType: 'date', order: false, download: true  }
  ];

  route = 'admin/poll';
  stringsToRemove = [''];

  constructor()
  {
    super();
    this.model = {
      pollID: 0,
      liveID: 0,
      escenaID: 0,
      title: '',
      subtitle: '',
      description: '',
      isActive: false,
      createdAt: new Date(),
      updatedAt: new Date(),
      createdBy: '',
      pollQuestions: [],
      live: null,
      escena: null
    };
  }
}
