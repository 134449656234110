// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: 'V.2.0.0',
  // URL_API: 'https://2998-190-144-168-125.ngrok.io/',
  // URL_API: 'https://dev.api.nvivo.cheil.com.co/',
  URL_API: 'https://4f06-190-144-168-125.ngrok-free.app'
  // URL_API: 'https://api.live.cheil.com.co/',
  // URL_API: 'http://localhost:5005/',
  // URL_API: 'http://f6ee-2800-484-467d-ea5f-14fe-dc04-1c5e-4452.ngrok.io/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
