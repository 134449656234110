import { Component, Input, OnInit } from '@angular/core';
import { AbstractService } from '@services/Abstract.service';
import { Images } from '@constants/images';

@Component({
  selector: 'app-input-image-form-data',
  templateUrl: './input-image-formData.component.html',
  styleUrls: ['./input-image-formData.component.scss']
})
export class InputImageFormDataComponent implements OnInit {
  @Input() public service: AbstractService | null = null;
  @Input() label = '';
  @Input() cellType = '';
  @Input() name = '';
  @Input() required = false;
  @Input() multiple = false;
  filesImage: File[] = [];

  constructor(
      public images: Images
  ) { }

  ngOnInit(): void {
  }

  public onFilesAdded = (event: any) =>  {
    console.log(event);
    const files = event.hasOwnProperty('addedFiles') ? event.addedFiles : event.target.files;
    if (this.service !== null) {
      this.service.modelClass.model[this.name] = files;
    }
  }
}
