import { Injectable } from '@angular/core';
import { AbstractClass } from './abstract.class';
import { ChatMessage } from '@models/chat-message';

@Injectable({
  providedIn: 'root'
})
export class ChatMessageClass extends AbstractClass{
  model: ChatMessage;
  modelName = 'LiveChatMessage';
  langBase = 'admin.components.live.chat.';
  idName = 'LiveChatMessageID';
  formCells = [];
  formTabs = [];
  formAccordion = [];
  tableCells = [];
  route = '';
  stringsToRemove = [];

  constructor()
  {
    super();
    this.model = {
      liveChatMessageID: null,
      replyToMessageID: null,
      userMSG: {
        name: '',
        email: ''
      },
      txtMessage: '',
      isBrand: true,
      reply: {
        userMSG: {
          name: '',
          email: '',
        },
        txtMessage: ''
      },
      liveBroadcastID: '',
      escenaBroadcastID: ''
    };
  }
}
