import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Scene } from '@models/scene';
import { SceneService } from '@services/cms/scene.service';
declare var configurations: any;
declare var $: any;

@Component({
  selector: 'app-show-scene',
  templateUrl: './show-scene.component.html',
  styleUrls: ['./show-scene.component.scss']
})
export class ShowSceneComponent implements OnInit {
  private id: any | null;
  public title = '';
  public scene: Scene | null = null;
  public sceneVideoUrl: string | undefined = '';
  // Alert Text
  public titleAlert = '';
  public descriptionAlert = '';
  // Variable Alert
  public showAlert = false;
  public url_front_script = configurations.url_front_script;
  public userRolesAuth: number[] = [1,2];
  public userRole: string;

  constructor(
      public service: SceneService,
      private activatedRoute: ActivatedRoute,
  ) {
    this.title = this.service.modelClass.langBase + 'singularTitle';
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.userRole = sessionStorage.getItem('role') || '0';
  }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {
    this.scene = null;
    this.service.getByID(this.id).then((result) => {
      if (result) {
        this.scene = this.service.data.data;
        this.sceneVideoUrl = this.scene?.escenaFrontVideoURL;
        // console.log(this.scene);
      }
    });
    this.showAlert = false;
  }

  public isAdmin = (): boolean => this.userRolesAuth.includes(Number(this.userRole));

  public close = () => {
    history.back();
  }

  public setAlert = () => {
    if (this.scene !== null) {
      // console.log(this.scene);
      if (!this.scene.isActive) {
        this.titleAlert = 'commons.alert.scene.activate';
        this.descriptionAlert = 'commons.alert.scene.activateDescription';
      } else {
        this.titleAlert = 'commons.alert.scene.deactivate';
        this.descriptionAlert = 'commons.alert.scene.deactivateDescription';
      }
      this.showAlert = true;
    }
  }

  public activateOrDeactivateScene = () => {
    if (this.scene !== null) {
      // console.log("DE - ACTIVATE", this.scene);
      this.scene.isActive = !this.scene.isActive;
      this.service.modelClass.model = this.scene;
      this.service.activateOrDeactivateScene(this.scene.escenaID).then((data: any) => {
        this.loadData();
      });
    }
  }
}
