import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './modules/auth/auth.component';
import { AdminLayoutComponent } from './modules/admin/admin-layout.component';
import { LandingsComponent } from './modules/landings/landings.component';

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  { path: 'auth', component: AuthComponent, pathMatch: 'full' },
  /** Admin */
  {
    path: 'admin',
    // tslint:disable-next-line:object-literal-sort-keys
    component: AdminLayoutComponent,
    children: [{
      path: '',
      // tslint:disable-next-line:object-literal-sort-keys
      loadChildren: () => import('./modules/admin/admin-layout.module').then((m) => m.AdminLayoutModule),
    }],
  },
  /** Landings */
  {
    path: 'landing',
    // tslint:disable-next-line:object-literal-sort-keys
    component: LandingsComponent,
    children: [{
      path: '',
      // tslint:disable-next-line:object-literal-sort-keys
      loadChildren: () => import('./modules/landings/landings.module').then((m) => m.LandingsModule),
    }],
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
  imports:  [
    RouterModule.forRoot(routes, { useHash: true, enableTracing: false, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
