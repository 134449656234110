<div class="div-alert">
    <div class="div-alert-background"></div>
    <div class="card div-alert-card">
        <div class="card-header">
            <h1>{{ title | translatePipe }}</h1>
        </div>
        <div class="card-body">
            <p>{{ description | translatePipe }}</p>
        </div>
        <div class="card-footer">
            <div *ngIf="type === 'action'">
                <div class="row">
                    <div class="col-6">
                        <app-button [classCss]="'btn-cancel'" [text]="'commons.alert.' + secondaryButton | translatePipe" [type]="'button'" (action)="sendAction(false)"></app-button>
                    </div>
                    <div class="col-6">
                        <app-button [classCss]="''" [text]="'commons.alert.' + primaryButton | translatePipe" [type]="'button'" (action)="sendAction(true)"></app-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
