import { Component, Input, OnInit } from '@angular/core';
import { Offer } from '@models/offer';
import { MatDialog } from '@angular/material/dialog';
import { Images } from '@constants/images';
import { OfferService } from '@services/cms/offer.service';
import { FormOfferComponent } from '../form-offer/form-offer.component';
import { OfferClass } from '@classes/offer.class';

@Component({
  selector: 'app-index-offer',
  templateUrl: './index-offer.component.html',
  styleUrls: ['./index-offer.component.scss']
})
export class IndexOfferComponent implements OnInit {
  @Input() liveId = 0;
  public list: any;
  public title = 'admin.components.offer.title';
  public subtitle = 'admin.components.offer.subtitle';

  // public list: Offer[] | null = null;

  constructor(
      public service: OfferService,
      public dialog: MatDialog,
      public images: Images
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {
    this.list = null;
    if (this.liveId === 0) {
      this.service.getAll().then((response) => {
        if (response) {
          this.list = this.service.data.data;
        }
      });
    } else {
      this.service.getByLiveID(this.liveId).then((response: any) => {
        if (response) {
          this.list = this.service.data.data;
        }
      });
    }
  }

  public openForm = () => {
    if (this.liveId > 0) {
      this.service.modelClass.model['liveID'] = this.liveId;
    }
    const dialogRef = this.dialog.open(FormOfferComponent, {
      width: '800px',
      data: this.service.modelClass.model
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data.reload) {
        this.loadData();
      }
    });
  }

  public newForm = () => {
    this.service.modelClass = new OfferClass();
    this.openForm();
  }

  public editForm = (live: any) => {
    if (live.length > 0) {
      this.service.modelClass.set(live[0]);
      this.openForm();
    }
  }
}
