<div class="questions-div" *ngIf="createQuestion !== null">
  <h1 class="title">Preguntas</h1>
  <div *ngIf="questions.length > 0">
    <div class="card card-question" *ngFor="let q of questions">
      <div class="card-body">
        <div class="row">
          <div class="col-9 col-md-10" (click)="openQuestion(q)">
            <p class="question-txt">{{ q.txtQuestion }}</p>
            <p class="question-type"><b>Tipo: </b>{{ q.pollQuestionType?.type }}</p>
          </div>
          <div class="col-3 col-md-2 div-img-btn">
            <img class="img-btn" (click)="openFormUpdate(q)" src="{{ images.iconAlert }}">
            <img class="img-btn" (click)="deleteQuestion(q)" src="{{ images.iconError }}">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-question add" *ngIf="questions.length < 1" (click)="openFormQuestion()">
    <div class="card-body">
      <div class="row">
        <div class="col-9 col-md-10">
          <p class="question-txt ">{{ createBtnQuestion | translatePipe }}</p>
        </div>
        <div class="col-3 col-md-2 div-img-btn">
          <img src="{{images.iconPlusCircle}}">
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #showQuestion let-modal *ngIf="questionSelected !== null && createQuestion !== null">
  <div class="card card-questionnaire-question">
    <div class="card-header">
      <h1 class="card-title">{{ 'Pregunta' }}</h1>
      <button type="button" class="close" (click)="modal.dismiss('close ok')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <p class="question">{{ questionSelected.txtQuestion }}</p>
      <div class="question-choices" *ngIf="questionSelected.pollQuestionType?.type === 'choice' || questionSelected.pollQuestionType?.type === 'multiple-choice'">
        <p class="question-choice {{ choice.isCorrectChoise ? 'correct' : '' }}" *ngFor="let choice of questionSelected.pollQuestionChoices">{{ choice.txtChoise }}</p>
      </div>
      <div class="question-text" *ngIf="questionSelected.pollQuestionType?.type === 'ranking'">
        <input type="text">
      </div>
      <div class="question-text" *ngIf="questionSelected.pollQuestionType?.type === 'text'">
        <input type="text">
      </div>
    </div>
    <div class="card-footer">
      <div class="text-right">
        <button type="button" class="btn-xpert btn-bg-light mr-2" (click)="modal.dismiss('close ok')">{{ 'commons.close' | translatePipe }}</button>
      </div>
    </div>
  </div>
</ng-template>
<div *ngIf="showFormQuestion && createQuestion !== null" class="div-card-questionnaire-question-form">
  <div class="card card-questionnaire-question-form">
    <form (ngSubmit)="addQuestion()" ngNativeValidate>
      <div class="card-header">
        <h1 class="title">{{ 'Agregar pregunta' | translatePipe }}</h1>
        <img class="img-close" src="{{ imageClose }}" alt="img_close" (click)="showFormQuestion = false">
      </div>
      <div class="card-body">
        <div class="row">
          <mat-form-field class="xpert-form-input col-12" appearance="standard">
            <label>Pregunta</label>
            <textarea matInput [(ngModel)]="createQuestion.txtQuestion" name="{{ 'txtQuestion' }}" [required]="true">
            </textarea>
          </mat-form-field>

          <mat-form-field class="xpert-form-input col-12" appearance="standard">
            <label>Tipo de pregunta</label>
            <mat-select class="form-control" panelClass="my-select" placeholder="{{ 'commons.forms.select' | translatePipe }}" [(ngModel)]="createQuestion.pollQuestionTypeID" name="{{ 'reviewQuestionTypeID' }}" [required]="true">
              <mat-option [value]="" [disabled]="true">{{ 'commons.forms.select' | translatePipe }}</mat-option>
              <mat-option [value]="qt.pollQuestionTypeID" *ngFor="let qt of questionTypes">{{ 'commons.forms.selected.' + qt.type | translatePipe }}</mat-option>
            </mat-select>
          </mat-form-field>

          <!--mat-form-field class="xpert-form-input col-12 col-md-6" appearance="standard">
            <label>Tipo de respuesta</label>
            <mat-select class="form-control" placeholder="{{ 'commons.forms.select' | translatePipe }}" [(ngModel)]="createQuestion.reviewAnswerType" name="{{ 'reviewAnswerType' }}" [required]="true">
              <div class="select-options-div">
                <mat-option [value]="" [disabled]="true">{{ 'commons.forms.select' | translatePipe }}</mat-option>
                <mat-option [value]="'Boolean'">{{ 'commons.forms.response-type.' + 'Boolean' | translatePipe }}</mat-option>
                <mat-option [value]="'DateTime'">{{ 'commons.forms.response-type.' + 'DateTime' | translatePipe }}</mat-option>
                <mat-option [value]="'Double'">{{ 'commons.forms.response-type.' + 'Double' | translatePipe }}</mat-option>
                <mat-option [value]="'Int'">{{ 'commons.forms.response-type.' + 'Int' | translatePipe }}</mat-option>
                <mat-option [value]="'String'">{{ 'commons.forms.response-type.' + 'String' | translatePipe }}</mat-option>
              </div>
            </mat-select>
          </mat-form-field -->

          <div *ngIf="createQuestion?.pollQuestionTypeID === 1 || createQuestion?.pollQuestionTypeID === 2 && showFormChoices" class="div-choices col-12">
            <div class="col-12">
              <div class="row title-tab-choices">
                <h1 class="choices-table-title col-9">Respuesta</h1>
                <h1 class="choices-table-title text-center col-2">Verdadera(s)</h1>
                <h1 class="choices-table-title col-1"></h1>
              </div>
            </div>
            <div *ngFor="let choice of createQuestion?.pollQuestionChoices" class="col-12">
              <div class="row form-choice">
                <div class="col-9">
                  <input [(ngModel)]="choice.txtChoise" name="{{ 'txtChoise' + choice.pollQuestionChoiceID }}" type="text" class="choice-answer-input">
                </div>
                <div class="col-2">
                  <input [(ngModel)]="choice.isCorrectChoise" type="checkbox" name="{{ 'correctAnswers' + choice.pollQuestionChoiceID }}" (ngModelChange)="selectCorrectChoice(choice)" class="choice-answer-input">
                </div>
                <div class="col-1">
                  <img class="img-btn" (click)="deleteChoice(choice)" src="{{ images.iconError }}">
                </div>
              </div>
            </div>
            <div class="btn-img">
              <img src="{{images.iconPlusCircle}}" (click)="addChoiceToQuestion()">
            </div>
          </div>
        </div>
        <p class="error" *ngIf="showErrorNotCorrectChoice">{{ service?.modelClass?.langBase + 'errorNotCorrectChoice' | translatePipe }}</p>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-12 col-md-6"></div>
          <div class="col-12 col-md-3"><app-button [type]="'button'" [classCss]="'btn-cancel'" (action)="showFormQuestion = false" [text]="'commons.cancel'"></app-button></div>
          <div class="col-12 col-md-3"><app-button [type]="'submit'" [classCss]="''" [text]="'commons.add'"></app-button></div>
        </div>
      </div>
    </form>
  </div>
</div>
