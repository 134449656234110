<div *ngIf="list !== null" class="admin-content-show-poll">
    <div class="admin-content-header">
        <h1 class="admin-content-title">{{ title | translatePipe }}{{ ' ' + data.title }}</h1>
        <h2 class="admin-content-title-back" (click)="close()">Volver</h2>
    </div>
    <hr>
    <div class="admin-content-tab">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="details">
                <a class="nav-link active" id="details-tab" data-toggle="tab" href="#details" role="tab" aria-controls="details"
                   aria-selected="true">{{ 'admin.components.live.liveDetails.basicData' | translatePipe }}</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
                <app-table-list
                        *ngIf="list !== null"
                        [myClass]="myClass"
                        [data]="list"
                ></app-table-list>
            </div>
        </div>
    </div>
</div>
