import { Injectable } from '@angular/core';
import { CustomHttpService } from '@services/generals/customHttp.service';
import { AbstractService } from '@services/Abstract.service';
import { PollClass } from '@classes/poll.class';

@Injectable({
    providedIn: 'root'
})
export class PollService extends AbstractService {

    constructor(
        public customHttpService: CustomHttpService
    ) {
        super(customHttpService, new PollClass());
    }

    public getByLiveID = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/GetByLiveID/' + id).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }

    public getByPollID = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/GetPollsResponcesByPollID/' + id).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }

    public getByID = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/GetByID/' + id).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }

    public create = () => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpPOST(
                    true,
                    true,
                    url + this.modelClass.modelName + '/CreatePoll',
                    self.modelClass.getJsonBase()
                ).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(true);
                    }
                );
            }
        );
    }

    /** Funcion que actualiza el Modelo */
    async update(id: number): Promise<any> {
        const self = this;
        const url = self.version + self.base;
        return await new Promise(
            resolve => {
                self.customHttpService.httpPUT(
                    true,
                    true,
                    url + this.modelClass.modelName + '/UpdatePoll/' + id,
                    this.modelClass.getJsonUpdate()
                ).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        resolve(true);
                    }
                );
            }
        );
    }

    public sendPollToFront = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpPATCH(true, true, url + this.modelClass.modelName + '/SendPollToFront/' + id).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }




    public GetIfExist = (id: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + 'Review/ExistQuestion/' + id).then(
                    (response) => {
                        resolve(response['data']);
                    }
                );
            }
        );
    }

    async deleteQuestion(id: number): Promise<any> {
        const self = this;
        const url = self.version + self.base;
        return await new Promise(
            resolve => {
                self.customHttpService.httpDELETE(
                    true,
                    true,
                    url + 'Review/DeleteQuestion/' + id,
                ).then(
                    (response) => {
                        console.log('Que hace este borrado ?: ', response);

                        if (response === false) { resolve(response); }
                        resolve(true);
                    }
                );
            }
        );
    }
}
