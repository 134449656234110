<div class="card card-popup-form" *ngIf="service !== undefined">
    <form (ngSubmit)="sendForm()" ngNativeValidate>
        <div class="card-header">
            <h1 class="title">{{ title | translatePipe }}</h1>
            <img class="img-close" src="{{ imageClose }}" alt="img_close" (click)="close()">
        </div>
        <div class="card-body">
            <div class="row">
                <app-field-form class="col-sm-12 col-md-6"
                                [service]="service"
                                [name]="'liveID'"
                                [cellType]="'liveID'"
                                [label]="'live'"
                                [required]="true"
                                [multiple]="false"
                                [disable]="true"></app-field-form>
                <app-field-form class="col-sm-12 col-md-6"
                                [service]="service"
                                [name]="'hintType'"
                                [cellType]="'hintType'"
                                [label]="'hintType'"
                                [required]="true"
                                [multiple]="false"
                                [disable]="false"></app-field-form>
                <app-field-form class="col-sm-12 col-md-12"
                                *ngIf="service.modelClass.model['hintType'] === 'Escena'"
                                [service]="service"
                                [name]="'contentID'"
                                [cellType]="'sceneID'"
                                [label]="'scene'"
                                [required]="true"
                                [multiple]="false"
                                [disable]="false"></app-field-form>
                <app-field-form class="col-sm-12 col-md-12"
                                *ngIf="service.modelClass.model['hintType'] === 'Oferta'"
                                [service]="service"
                                [name]="'contentID'"
                                [cellType]="'offerID'"
                                [label]="'offer'"
                                [required]="true"
                                [multiple]="false"
                                [disable]="false"></app-field-form>
                <app-field-form class="col-sm-12 col-md-12"
                                [service]="service"
                                [name]="'hintTxt'"
                                [cellType]="'textarea'"
                                [label]="'hintTxt'"
                                [required]="true"
                                [multiple]="false"
                                [disable]="false"></app-field-form>

                <div class="col-12">
                    <p class="error" *ngIf="error">{{ 'commons.forms.errorFields' | translatePipe }}<b *ngFor="let error of errors">{{ service.modelClass.langBase + error | translatePipe }}</b></p>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-12 col-md-6"></div>
                <div class="col-12 col-md-3"><app-button [type]="'button'" [classCss]="'btn-cancel'" (action)="close()" [text]="'commons.cancel'"></app-button></div>
                <div class="col-12 col-md-3"><app-button [type]="'submit'" [classCss]="''" [text]="'commons.accept'"></app-button></div>
            </div>
        </div>
    </form>
</div>
