<div class="chat-div">
    <div class="chat-content">
        <div id="chat-messages" class="chat-messages">
            <div class="chat-message" *ngFor="let message of messages">
                <div class="{{ message.isBrand ? 'brand' : 'user' }}" (click)="replyMessage(message)">
                    <p class="chat-message-name">{{ message.userMSG.name }}</p>
                    <p class="chat-message-txt">{{ message.txtMessage }}</p>
                    <p class="chat-message-email" *ngIf="!message.isBrand">{{ message.userMSG.email }}</p>
                    <div class="reply" *ngIf="message.reply !== null">
                        <p class="chat-message-name">{{ message.reply.userMSG.name }}</p>
                        <p class="chat-message-txt">{{ message.reply.txtMessage }}</p>
                        <p class="chat-message-email">{{ message.reply.userMSG.email }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="chat-inputs">
            <div class="chat-reply-message" *ngIf="replyMessageVar">
                <button class="btn-close" type="button" (click)="deleteReplyMessage()"><i class="material-icons">close</i></button>
                <p class="chat-reply-message-title">{{ this.service.modelClass.langBase + 'replyMessage' | translatePipe }}</p>
                <p class="chat-reply-message-name">{{ service.modelClass.model['reply'].userMSG.name }} - {{ service.modelClass.model['reply'].userMSG.email }}</p>
                <p class="chat-reply-message-txt">{{ service.modelClass.model['reply'].txtMessage }}</p>
                <button class="btn-block" type="button" (click)="blockUser(service.modelClass.model['reply'])">Bloquear</button>
            </div>
            <form (submit)="sendMessage()" ngNativeValidate>
                <div class="row">
                    <div class="col-12 col-md-8 col-lg-10">
                        <app-field-form
                                [label]="'txtMessage'"
                                [placeholder]="'Text'"
                                [cellType]="'text'"
                                [name]="'txtMessage'"
                                [required]="true"
                                [service]="service"
                        >
                        </app-field-form>
                    </div>
                    <div class="col-12 col-md-4 col-lg-2 btn-send">
                        <app-button [type]="'submit'" [text]="service.modelClass.langBase + 'sendMessage'"></app-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
