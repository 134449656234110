import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminLayoutRoutes } from './admin-layout.routing';
import { NgAmpModule } from 'ng-amp';
import { NgxChartsModule } from '@swimlane/ngx-charts';
/** PIPES */
import { PipesModule } from '@pipes/pipes.module';
/** SERVICES */
import { UtilsService } from '@services/generals/utils.service';
import { CustomHttpService } from '@services/generals/customHttp.service';

/** PAGES */
import { HomeComponent } from './pages/home/home.component';
import { IndexLiveComponent } from './pages/live/index-live/index-live.component';
import { FormLiveComponent } from './pages/live/form-live/form-live.component';

/** COMPONENTS */
import { FragmentsModule } from '../../fragments/fragments.module';
import { ComponentsModule } from '@components/components.module';
import { IndexOfferComponent } from './pages/offer/index-offer/index-offer.component';
import { FormOfferComponent } from './pages/offer/form-offer/form-offer.component';
import { ShowLiveComponent } from './pages/live/show-live/show-live.component';
import { HighlightModule } from 'ngx-highlightjs';
import { InfoLiveComponent } from './pages/live/info-live/info-live.component';
import { IndexAccountComponent } from './pages/account/index-account/index-account.component';
import { FormAccountComponent } from './pages/account/form-account/form-account.component';
import { ShowAccountComponent } from './pages/account/show-account/show-account.component';
import { IndexTopicComponent } from './pages/topic/index-topic/index-topic.component';
import { FormTopicComponent } from './pages/topic/form-topic/form-topic.component';
import { ShowTopicComponent } from './pages/topic/show-topic/show-topic.component';
import { StatsLiveComponent } from './pages/live/stats-live/stats-live.component';
import { IndexSceneComponent } from './pages/scene/index-scene/index-scene.component';
import { FormSceneComponent } from './pages/scene/form-scene/form-scene.component';
import {ShowSceneComponent} from "./pages/scene/show-scene/show-scene.component";
import {IndexPollComponent} from "./pages/poll/index-poll/index-poll.component";
import {FormPollComponent} from "./pages/poll/form-poll/form-poll.component";
import {IndexHintComponent} from "./pages/hint/index-hint/index-hint.component";
import {FormHintComponent} from "./pages/hint/form-hint/form-hint.component";
import {FormsModule} from "@angular/forms";
import {ShowPollComponent} from "./pages/poll/show-poll/show-poll.component";


/** EXTERNAL */

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(AdminLayoutRoutes),
        PipesModule,
        FragmentsModule,
        ComponentsModule,
        HighlightModule,
        NgAmpModule,
        NgxChartsModule

    ],
    // tslint:disable-next-line:object-literal-sort-keys
    declarations: [
        /** Pages */
        HomeComponent,
        IndexLiveComponent,
        FormLiveComponent,
        IndexOfferComponent,
        FormOfferComponent,
        ShowLiveComponent,
        InfoLiveComponent,
        StatsLiveComponent,
        IndexAccountComponent,
        FormAccountComponent,
        ShowAccountComponent,
        IndexTopicComponent,
        FormTopicComponent,
        ShowTopicComponent,
        IndexSceneComponent,
        FormSceneComponent,
        ShowSceneComponent,
        IndexPollComponent,
        FormPollComponent,
        ShowPollComponent,
        IndexHintComponent,
        FormHintComponent
    ],
    providers: [
        /** Services */
        UtilsService,
        CustomHttpService,
    ],
    exports: [],
})

export class AdminLayoutModule {}
