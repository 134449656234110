import { Injectable } from '@angular/core';
import { CustomHttpService } from '@services/generals/customHttp.service';
import { AbstractService } from '@services/Abstract.service';
import { AccountClass } from '@classes/account.class';

@Injectable({ providedIn: 'root'})
export class AccountService extends AbstractService {
    constructor(
        public customHttpService: CustomHttpService
    ) {
        super(customHttpService, new AccountClass());
    }
}
