<div class="card card-popup-form" *ngIf="service !== undefined">
  <form (ngSubmit)="save()" ngNativeValidate>
    <div class="card-header">
      <h1 class="title">{{ title | translatePipe }}</h1>
      <img class="img-close" src="{{ imageClose }}" alt="img_close" (click)="close()">
    </div>
    <div class="card-body">
      <div class="row">
        <app-field-form *ngFor="let input of service.modelClass.getInputsForm()" class="col-sm-12 col-md-{{ input.cols }}"
                        [service]="service"
                        [name]="input.name"
                        [cellType]="input.cellType"
                        [label]="input.label"
                        [required]="input.required"
                        [multiple]="input.multiple"
                        [disable]="input.disable"></app-field-form>
        <div class="col-12">
          <p class="error" *ngIf="error">{{ 'commons.forms.errorFields' | translatePipe }}<b *ngFor="let error of errors">{{ service.modelClass.langBase + error | translatePipe }}</b></p>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <div class="row">
        <div class="col-12 col-md-6"></div>
        <div class="col-12 col-md-3"><app-button [type]="'button'" [classCss]="'btn-cancel'" (action)="close()" [text]="'commons.cancel'"></app-button></div>
        <div class="col-12 col-md-3"><app-button [type]="'submit'" [classCss]="''" [text]="'commons.accept'"></app-button></div>
      </div>
    </div>
  </form>
</div>
