import { Injectable } from '@angular/core';
import { CustomHttpService } from '@services/generals/customHttp.service';

@Injectable({
  providedIn: 'root'
})
export class CheilConnectService {
  public data: any;
  private urlConnect = 'https://live.connect.cheil.com.co/';

  constructor(
    public customHttpService: CustomHttpService,
  ) { }

  public getDepartments = () => {
    const self = this;
    return new Promise(
      resolve => {
        self.customHttpService.httpGETURL(true, true, this.urlConnect + 'api/departments').then(
          (response: any) => {
            if (response === false) { resolve(response); }
            else {
              this.data = response;
              resolve(true);
            }
          }
        );
      }
    );
  }

  public getCitiesByDepartments = (city: number) => {
    const self = this;
    return new Promise(
        resolve => {
          self.customHttpService.httpGETURL(true, true, this.urlConnect + 'api/department/' + city + '/cities').then(
              (response: any) => {
                if (response === false) { resolve(response); }
                else {
                  this.data = response;
                  resolve(true);
                }
              }
          );
        }
    );
  }

}
