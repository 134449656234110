<div class="admin-content-header">
    <h1 class="admin-content-title">{{ title | translatePipe }}</h1>
</div>
<hr>
<app-table-list
        *ngIf="list !== null"
        [myClass]="service.modelClass"
        [data]="list"
        [activeActions]="false"
        [showEditAction]="true"
        (createAction)="newForm()"
        (editAction)="editForm($event)"
></app-table-list>
