import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { NotificationFacade } from '@redux/notification.facade';
import { ChatMessage } from '@models/chat-message';
declare var configurations: any;

@Injectable({
    providedIn: 'root'
})
export class HubService {
    public hubConnection: signalR.HubConnection;
    public signalReceiveMessage = new EventEmitter<ChatMessage>()
    private url = '';

    constructor(
        public notificationFacade: NotificationFacade
    ) {
        this.url = configurations.url_env + 'livechat';
        this.hubConnection = this.buildConnection();
        this.startConnection();
    }

    public connectionStateChanged = (state: { newState: number, oldState: number }) => {
        const stateConversion = ['connecting', 'connected', 'reconnecting', 'disconnected'];
        console.log('SignalR state changed from: ' + stateConversion[state.oldState]
            + ' to: ' + stateConversion[state.newState]);
    }

    public buildConnection = ():signalR.HubConnection => {
        return new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(this.url, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .build();
    }

    private startConnection = () => {
        this.hubConnection.start().then(() => {
            console.log('SignalR Connected!');
            this.registerSignalEvents();
            // this.connection.invoke('AddToGroup', this.liveBroadcastID);
        }).catch((err: any) => {
            this.notificationFacade.setError('Error conectandose al Socket');
            setTimeout(() => {
                this.hubConnection = this.buildConnection();
                this.startConnection();
            }, 10000);
            return true;
        });
    }

    public verifyConnection = () => {
        /* this.hubConnection.invoke('test', '').catch(() => {
            this.startConnection();
        }); */
    }

    private registerSignalEvents = () => {
        this.hubConnection.on('ReceiveMessage', (data: ChatMessage) => {
            this.signalReceiveMessage.emit(data);
        });
        this.hubConnection.on('ReceivePasos', (data: any) => {
            this.notificationFacade.setSuccess(data);
            console.log(data);
        });
        this.hubConnection.on('stateChanged', (data: any) => {
            console.log(data);
        });
    }

    public sendMessage = (msg: any) => this.hubConnection.invoke('SendMessageAsync', msg);
    public leaveToGroup = (group: string) => this.hubConnection.invoke('LeaveToGroup', group);
    public joinToGroup = (group: string) => {
        try {
            this.hubConnection.invoke('AddToGroup', group);
        } catch (e) {
            console.log(e);
        }

    }
}
