import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() public type = 'button';
  @Input() public classCss = '';
  @Input() public text = 'cancel';
  @Output() action = new EventEmitter<any>();

  constructor() {}

  public returnEmitter = () => {
    this.action.emit();
  }
}
