import { Injectable } from '@angular/core';
import { CustomHttpService } from '@services/generals/customHttp.service';
import { AbstractService } from '@services/Abstract.service';
import { TopicClass } from '@classes/topic.class';

@Injectable({ providedIn: 'root'})
export class TopicService extends AbstractService {
    constructor(
        public customHttpService: CustomHttpService
    ) {
        super(customHttpService, new TopicClass());
    }

    public getByAccountID = (accountId: number) => {
        const self = this;
        const url = self.version + self.base;
        return new Promise(
            resolve => {
                self.customHttpService.httpGET(true, true, url + this.modelClass.modelName + '/GetByAccountID/' + accountId).then(
                    (response) => {
                        if (response === false) { resolve(response); }
                        self.data = response;
                        resolve(true);
                    }
                );
            }
        );
    }
}
