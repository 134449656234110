import { Component, Input, OnInit } from '@angular/core';
import { Live } from '@models/live';
import { MatDialog } from '@angular/material/dialog';
import { Images } from '@constants/images';
import { Scene } from '@models/scene';
import { ScenesClass } from '@classes/scenes.class';
import { SceneService } from '@services/cms/scene.service';
import { FormSceneComponent } from '../form-scene/form-scene.component';

@Component({
  selector: 'app-index-scene',
  templateUrl: './index-scene.component.html',
  styleUrls: ['./index-scene.component.scss']
})
export class IndexSceneComponent implements OnInit {
  @Input() live: Live | null = null;
  public title = 'admin.components.scene.title';
  public subtitle = 'admin.components.scene.subtitle';
  public createBtn = 'admin.components.scene.createBtn';
  public notificationTitleDelete = 'cms.components.scene.notificationTitleDelete';
  public notificationMessageDelete = `cms.components.scene.notificationMessageDelete`;
  // Alert Text
  public titleAlert = `commons.alert.scene.active`;
  public descriptionAlert = `commons.alert.scene.inactive`;
  // Variable Alert
  public showAlert = false;
  public showLiveComponent = false;

  public list: Scene[] | null = null;
  public sceneSelected: Scene = new ScenesClass().model;
  public dataScene: any;

  constructor(
      public service: SceneService,
      public dialog: MatDialog,
      public images: Images
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {
    this.list = null;
    if (this.live !== null) {
      this.service.getEscenasByLiveID(this.live.liveID).then((response) => {
        if (response) {
          // console.log(this.service.data);
          this.list = this.service.data.data;
          this.showAlert = false;
        }
      });
    }
  }

  public openForm = () => {
    if (this.live !== null) {
      this.service.modelClass.model['liveID'] = this.live.liveID;
    }
    const dialogRef = this.dialog.open(FormSceneComponent, {
      width: '800px',
      data: this.service.modelClass.model
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data.reload) {
        this.loadData();
      }
    });
  }

  public newForm = () => {
    this.service.modelClass = new ScenesClass();
    this.openForm();
  }

  public editForm = (scene: any) => {
    if (scene.length > 0) {
      this.live = scene[0].live;
      this.service.modelClass.set(scene[0]);
      this.openForm();
    }
  }

  public openShowComponent = (scene: any) => {
    // console.log(scene);
    if (scene.length > 0) {
      this.service.modelClass.set(scene[0]);
      window.open('#/' + this.service.modelClass.route + '/' + this.service.modelClass.model[this.service.modelClass.idName], '_self');
    }
  }

  public playStopLive = (r: boolean) => {
    if (r) {
      if (!this.sceneSelected.isActive) {
        /* this.service.playLive(this.liveSelected.liveID, this.liveSelected.liveOnAir).then((data: any) => {
          this.loadData();
        }); */
      } else {
        /* this.service.stopLive(this.liveSelected.liveID, this.liveSelected.liveOnAir).then((data: any) => {
          this.loadData();
        }); */
      }
    } else {
      this.showAlert = false;
    }
  }

  public setAlert = (live: Live[]) => {
    if (live.length > 0) {
      /* console.log(live);
      this.liveSelected = live[0];
      if (!this.liveSelected.livePlay) {
        this.titleAlert = `commons.alert.live.play`;
        this.descriptionAlert = `commons.alert.live.playDescription`;
      } else {
        this.titleAlert = `commons.alert.live.stop`;
        this.descriptionAlert = `commons.alert.live.stopDescription`;
      }
      this.showAlert = true; */
    }
  }
}
