import { Injectable } from '@angular/core';

@Injectable()
export class Images {
  // Menu Images
  static iconHome = './assets/images/mso_icon_home.svg';
  static iconMarketData = './assets/images/mso_icon_market_data.svg';
  static iconUsers = './assets/images/mso_icon_users.svg';
  static iconDeviceSettings = './assets/images/mso_icon_device_settings.svg';
  static iconGeneralSettings = './assets/images/mso_icon_general_settings.svg';
  static iconStore = './assets/images/mso_icon_store.svg';
  static iconStoreExecution = './assets/images/mso_icon_store_execution.svg';
  static iconTrash = './assets/images/mso_icon_trash.svg';
  static iconFilter = './assets/images/mso_icon_filter.svg';
  static iconEdit = './assets/images/mso_icon_edit.svg';
  static iconSearch = './assets/images/mso_icon_search.svg';
  static iconClosePopup = './assets/images/mso_icon_close_popup.svg';
  static iconArrowBottom = './assets/images/mso_icon_arrow_bottom.svg';
  static arrowLeft = './assets/images/next_left.svg';
  static arrowRight = './assets/images/next_right.svg';
  static iconPaginatorLast = './assets/images/mso_icon_paginator_last.svg';
  static iconPaginatorFirst = './assets/images/mso_icon_paginator_first.svg';
  iconSamsung = './assets/images/Logo-SAMSUNG.png'

  // Menu new images
  iconUser = './assets/images/live_ellipse.png';
  static iconUser2x = './assets/images/live_ellipse@2x.png';
  static iconUser3x = './assets/images/live_ellipse@3x.png';
  static iconLiveHomeBlue = './assets/images/live_ep-menu.svg';
  static iconLiveHomeGray = './assets/images/live_icon-outline-grid.svg';
  static iconLiveBlue = './assets/images/live_icon-chat.svg';
  static iconLiveGray = './assets/images/live_ep-chat-dot-round.svg';
  static iconLiveOfferBlue = './assets/images/live_color.svg';
  static iconLiveOfferGray = './assets/images/live_color-2.svg';
  static iconLiveReportBlue = './assets/images/live_fluent-clipboard-bullet-list.svg';
  static iconLiveReportGray = './assets/images/live_icon-outline-bell.svg';
  static iconLiveMessageBlue = './assets/images/live_icon-fill-message-circle.svg';
  static iconLivePowerBlue = './assets/images/live_icon_outline-power.svg';
  static iconLivePowerGray = './assets/images/live_icon_outline-power-2.svg';
  static arrowDown = './assets/images/live_feather-icon-chevron-down.svg';

  // Another Images
  loader = './assets/images/mso_loader.svg';
  logo = './assets/images/mso_logo.svg';
  iconInformation = './assets/images/mso_icon_information.svg';
  iconAlert = './assets/images/mso_icon_alert.svg';
  iconSuccess = './assets/images/mso_icon_success.svg';
  iconError = './assets/images/mso_icon_error.svg';
  iconFilterNonStatic = './assets/images/mso_icon_filter.svg';
  iconPlusCircle = './assets/images/mso_icon_plus_circle.svg';
  imageBanner = './assets/images/lb_banner.jpg';
  imageCheil = './assets/images/live_cheil.svg';
  imageUserImage = './assets/images/live_ellipse@3x.png';
}
