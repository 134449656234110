import {AfterContentChecked, Component, Input, OnInit} from '@angular/core';
import { AbstractService } from '@services/Abstract.service';
@Component({
  selector: 'app-field-form',
  templateUrl: './field_form.component.html',
  styleUrls: ['./field_form.component.scss']
})

export class FieldFormComponent implements OnInit, AfterContentChecked {
  @Input() public service: AbstractService | null = null;
  @Input() label = '';
  @Input() cellType = '';
  @Input() name = '';
  @Input() disable = false;
  @Input() required = false;
  @Input() multiple = false;
  @Input() placeholder = '';
  ckeConfig: any;
  /** */
  public siteLocations: Location[] = [];
  public timeZones: any[] = [];
  // Filters
  public selectTemporal: any[] = [];
  public exceptions = ['active', 'image', 'liveID', 'cheilConnectDepartments', 'cheilConnectCities',
    'cheilConnectTypeId', 'image-form-data', 'accountID', 'topicID' , 'color' , 'color-live', 'sceneID',
    'hintType', 'offerID', 'themeID'];

  constructor() {}

  ngOnInit(): void {
    const self = this;
    self.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };
  }

  // tslint:disable-next-line:typedef
  ngAfterContentChecked() {
    const self = this;
  }

  /** Getters */

  /** Fin Getters */

  public selectFile = ($event: any, name: string) => {
    if (this.service !== null) { this.service.modelClass.model[name] = this.getImageBase64($event); }
  }

  /**
   * Obtiene los datos de la imagen y la codifica en base64
   */
  private getImageBase64 = (image: any) => {
    const imageBase64 = {
      height: 0,
      timestamp: '',
      uri: '',
      fileName: '',
      data: ''
    };
    const file: File = image.target.files[0];
    const myDate = new Date();
    const myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      imageBase64.height = file.size;
      imageBase64.timestamp = '2019-01-06T17:16:40';
      imageBase64.uri = file.name;
      imageBase64.fileName = file.name;
      const result = myReader.result ?? '';
      imageBase64.data = result.toString().split(',')[1] ?? '';
    };
    myReader.readAsDataURL(file);
    return imageBase64;
  }

  /** Selects */
  public select = (name: string, value: any) => {
    if (this.service?.modelClass) {
      if (typeof this.service.modelClass.model[name] === 'boolean') {
        this.service.modelClass.model[name] = !this.service.modelClass.model[name];
      } else if (typeof this.service.modelClass.model[name] === 'number') {
        this.service.modelClass.model[name] = this.service.modelClass.model[name] !== 1 ? 1 : 0;
      }
    }
  }
  /** End Selects */

  /** Filter Selects */
  // tslint:disable-next-line:typedef
  public onKey(e: any) {
    const filter = e.target.value.toLowerCase();
    switch (this.cellType) {
      case 'timeZone':
        if (this.selectTemporal.length === 0) { this.selectTemporal = this.timeZones; }
        this.timeZones = this.selectTemporal.filter(i => i.toLowerCase().includes(filter) );
        break;
    }
  }

  public color(){
    console.log(this.cellType);

  }
}
