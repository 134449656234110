import { Component, Input, OnInit } from '@angular/core';
import { LiveService } from '@services/cms/live.service';
import { Live } from '@models/live';
import { MatDialog } from '@angular/material/dialog';
import { FormLiveComponent } from '../form-live/form-live.component';
import { Images } from '@constants/images';
import { LiveClass } from '@classes/live.class';
import { Topic } from '@models/topic';

@Component({
  selector: 'app-index-live',
  templateUrl: './index-live.component.html',
  styleUrls: ['./index-live.component.scss']
})
export class IndexLiveComponent implements OnInit {
  @Input() topic: Topic | null = null;
  public title = 'admin.components.live.title';
  public subtitle = 'admin.components.live.subtitle';
  public createBtn = 'admin.components.live.createBtn';
  public notificationTitleDelete = 'cms.components.brand.notificationTitleDelete';
  public notificationMessageDelete = `cms.components.brand.notificationMessageDelete`;
  // Alert Text
  public titleAlert = `commons.alert.live.play`;
  public descriptionAlert = `commons.alert.live.playDescription`;
  // Variable Alert
  public showAlert = false;
  public showLiveComponent = false;
  public list: Live[] | null = null;
  public liveSelected: Live = new LiveClass().model;
  public dataLive: any;

  constructor(
      public service: LiveService,
      public dialog: MatDialog,
      public images: Images
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  public loadData = () => {
    this.list = null;
    if (this.topic === null) {
      /* this.service.getAll().then((response) => {
        if (response) {
          this.list = this.service.data.data;
          this.showAlert = false;
        }
      }); */
      this.service.getMyEvents().then((response) => {
        if (response) {
          this.list = this.service.data.data;
          this.showAlert = false;
        }
      });
    } else {
      this.service.getByTopicID(this.topic.topicID).then((response: any) => {
        if (response) {
          this.list = this.service.data.data;
          this.showAlert = false;
        }
      });
    }
  }

  public openForm = () => {
    if (this.topic !== null) {
      this.service.modelClass.model['topicID'] = this.topic.topicID;
      this.service.modelClass.model['accountID'] = this.topic.accountID;
      this.service.modelClass.model['adminBGColor'] = this.topic.adminBGColor;
      this.service.modelClass.model['adminMSGColor'] = this.topic.adminMSGColor;
    }
    const dialogRef = this.dialog.open(FormLiveComponent, {
      width: '800px',
      data: this.service.modelClass.model
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data.reload) {
        this.loadData();
      }
    });
  }

  public newForm = () => {
    this.service.modelClass = new LiveClass();
    this.openForm();
  }

  public editForm = (live: any) => {
    if (live.length > 0) {
      this.topic = live[0].topic;
      this.service.modelClass.set(live[0]);
      this.openForm();
    }
  }

  public openShowComponent = (live: any) => {
    if (live.length > 0) {
      this.service.modelClass.set(live[0]);
      window.open('#/' + this.service.modelClass.route + '/' + this.service.modelClass.model[this.service.modelClass.idName], '_self');
    }
  }

  public playStopLive = (r: boolean) => {
    if (r) {
      if (!this.liveSelected.livePlay) {
        this.service.playLive(this.liveSelected.liveID, this.liveSelected.liveOnAir).then((data: any) => {
          this.loadData();
        });
      } else {
        this.showAlert = false;
        this.service.stopLive(this.liveSelected.liveID, this.liveSelected.liveOnAir).then((data: any) => {
          this.loadData();
        });
      }
    } else {
      this.showAlert = false;
    }
  }

  public setAlert = (live: Live[]) => {
    if (live.length > 0) {
      // console.log(live);
      this.liveSelected = live[0];
      if (!this.liveSelected.livePlay) {
        this.titleAlert = `commons.alert.live.play`;
        this.descriptionAlert = `commons.alert.live.playDescription`;
      } else {
        this.titleAlert = `commons.alert.live.stop`;
        this.descriptionAlert = `commons.alert.live.stopDescription`;
      }
      this.showAlert = true;
    }
  }
}
