import {Component, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { Images } from '@constants/images';
import { LoaderFacade } from '@redux/loader.facade';
import { Loadable } from '@models/loadable';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnDestroy {
  public loader: Loadable = { loading: false, okMessage: '', error: '', success: true };
  public image = '';
  // @ts-ignore
  public unsubscribeLoader: Subscription;

  constructor(
    public loaderFacade: LoaderFacade,
    public images: Images
  ) {
    const self = this;
    self.image = images.loader;
    self.unsubscribeLoader = self.loaderFacade.loaderState$.subscribe(
      ok => {
        self.loader = ok;
      }
    );
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    const self = this;
    self.unsubscribeLoader.unsubscribe();
  }
}
